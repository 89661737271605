import { Button, Divider, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

interface DropMenuProps {
    menuItems: any[]
}

function DropMenu(props: DropMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (action: any) => {
        action();
        handleClose();
    }

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Menu
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {props.menuItems.map((item, index) =>
                    <div>
                        <MenuItem key={index} onClick={() => handleMenuClick(item.onClick)}>{item.name}</MenuItem>
                        {item.divider ? <Divider /> : null}
                    </div>
                )}
            </Menu>
        </div>
    );
}

export default DropMenu;