import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import { CAMERA_TYPES } from 'shared/interfaces/camera.interface'

interface RadioGroupProps {
    setter: (value: any) => void,
    getter: string
}

function RadioButtonGroup(props: RadioGroupProps) {

    const handleChange = (event: any) => {
        props.setter(event.target.value)
    }

    return (
        <div>
            <FormControl>
                <div className="flex justify-center">
                    <FormLabel>Camera Type</FormLabel>
                </div>
                <RadioGroup row value={props.getter} onChange={handleChange}>
                    <FormControlLabel value={CAMERA_TYPES.EXTERIOR} control={<Radio />} label="Exterior" />
                    <FormControlLabel value={CAMERA_TYPES.INTERIOR} control={<Radio />} label="Interior" />
                </RadioGroup>
            </FormControl>
        </div>
    )
}

export default RadioButtonGroup