import { Button, Chip, Grid, Paper } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import "./overlay-cell.css";

interface DragBoxProps {
    id: number;
    name: string;
    order: number;
    manditory: boolean;
    vehicleType: string;
    deleteFunction: (id: number) => Promise<void>;
    img: string;
    loadImageFunction: any;
    loadBackgroundFunction: any;
    setParametersFunction: any;
    resetFunction: any;
    mode: any;
    modeFunction: any;
    overlays: any
}

enum Mode {
    Draw,
    Edit
}

export function OverlayCell(props: DragBoxProps) {
    const [editText, setEditText] = useState<string>("Edit")

    const handleDelete = async (id: number) => {
        let currentOverlays = props.overlays
        let newOverlays = currentOverlays.filter((overlay: any) => overlay.id !== id)
        newOverlays.sort((a: any, b: any) => a.order - b.order)

        for (let i = 0; i < newOverlays.length; i++) {
            newOverlays[i].order = i + 1
        }

        props.deleteFunction(id)

        try {
            await axios.put('/api/overlay/reorder_overlays/', {
                overlays: newOverlays
            })
        } catch (err: any) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (props.mode === Mode.Draw) {
            setEditText("Edit")
        }
    }, [props.mode])

    const handleEditImage = async () => {
        try {
            setEditText("Cancel")

            if (props.mode === Mode.Edit) {
                props.resetFunction()
                props.modeFunction(Mode.Draw)
            }

            if (props.mode === Mode.Draw) {
                props.modeFunction(Mode.Edit)
                props.setParametersFunction(props.name, props.vehicleType)
                props.loadImageFunction(props.img)
            }

        } catch (err: any) {
            console.log(err)
        }
    }

    const viewImage = () => {
        let win = window.open();
        win?.document.write(`<img src=${props.img} />`);
    }

    return (
        <Paper className="drag-box-container">
            <Grid container display='flex' justifyContent='space-evenly' paddingX={1}>
                <Grid item xs={2} display='flex' alignItems='center'>
                    <p>{props.name} <Chip label={props.vehicleType} /></p>
                </Grid>
                <Grid item xs={3}>
                    <img onClick={viewImage} className="drag-box-text" src={props.img}></img>
                </Grid>
                <Grid item xs={2} display='flex'>
                    <Button className="drag-box-text" onClick={() => handleEditImage()}><EditIcon /></Button>
                </Grid>
                <Grid item xs={2} display='flex'>
                    <Button className="drag-box-text" onClick={async () => await handleDelete(props.id)}><DeleteIcon /></Button>
                </Grid>
            </Grid>
        </Paper>
    )
}