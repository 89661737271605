enum CameraTags {
    Unassigned = "Unassigned",
    LeftFront = "Left Front",
    LeftSide = "Left Side",
    LeftRear = "Left Rear",
    Rear = "Rear",
    RightRear = "Right Rear",
    RightSide = "Right Side",
    RightFront = "Right Front",
    Front = "Front",
    Roof = "Roof",
    LeftFrontLow = "Left Front Low",
    LeftSideLow = "Left Side Low",
    LeftRearLow = "Left Rear Low",
    RearLow = "Rear Low",
    RightRearLow = "Right Rear Low",
    RightSideLow = "Right Side Low",
    RightFrontLow = "Right Front Low",
    FrontLow = "Front Low",
    LeftFrontHigh = "Left Front High",
    LeftSideHigh = "Left Side High",
    LeftRearHigh = "Left Rear High",
    RearHigh = "Rear High",
    RightRearHigh = "Right Rear High",
    RightSideHigh = "Right Side High",
    RightFrontHigh = "Right Front High",
    FrontHigh = "Front High"
}

export default CameraTags