import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { get_overlays } from "state/overlays/action_creator";
import { OverlayDTO } from "../canvas/table/OverlayTable"
import AssignOverlayCard from "./AssignOverlayCard"
import { useDispatch, useSelector } from "react-redux"
import { State } from "state/reducers";

export default function AssignOverlayContainer() {
    const overlays = useSelector((state: State) => state.overlays.overlays) as OverlayDTO[]
    const dispatch = useDispatch();
    const getOverlaysAction = bindActionCreators(get_overlays, dispatch)
  
    useEffect(() => {
      getOverlaysAction();
    }, []);

    return (
        <div>
            {overlays.sort((a, b) => a.id - b.id).map((overlay, index) => (
                <AssignOverlayCard overlay={overlay} />
            ))}
        </div>
    )
}