import { combineReducers } from "redux";
import alertReducer from "./alert/reducer";
import cameraReducer from "./camera/reducer";
import rooftopReducer from "./rooftop/reducer";
import imagesReducer from './images/reducer';
import themeReducer from "./theme/reducer";
import authReducer from "./auth/reducer";
import vehicleReducer from "./vehicle/reducer";
import formValidationReducer from "./FormValidation/reducer";
import overlayReducer from "./overlays/reducer";

const reducers = combineReducers({
  "rooftop": rooftopReducer,
  "alert": alertReducer,
  "camera": cameraReducer,
  "images": imagesReducer,
  "theme": themeReducer,
  "auth": authReducer,
  "vehicle": vehicleReducer,
  "formValidation": formValidationReducer,
  "overlays": overlayReducer
})

export default reducers;
export type State = ReturnType<typeof reducers>