import { OverlayCell } from "./OverlayCell"
import { bindActionCreators } from "redux"
import { delete_overlay, get_overlays } from "state/overlays/action_creator"
import { useDispatch, useSelector } from "react-redux"
import { State } from "state/reducers";
import { useEffect } from "react";

export interface OverlayDTO {
    id: number,
    name: string,
    img: string,
    order: number,
    manditory: boolean,
    body_type: string
    sub_type?: string,
    phone_camera_lens: string,
}

export interface RooftopOverlay {
    id: number,
    order: number,
    manditory: boolean,
    phone_camera_lens: string,
}

interface OverlayTableProps {
    overlays: OverlayDTO[],
    loadImageFunction: any,
    loadBackgroundFunction: any
    setParametersFunction: any
    overlayOptions: any
    resetFunction: any
    mode: any
    modeFunction: any
    getCountFunction: any
}

export function OverlayTable(props: OverlayTableProps) {
    const rooftops = useSelector((state: State) => state.rooftop.rooftops)
    const overlays = useSelector((state: State) => state.overlays.overlays) as OverlayDTO[]
    const dispatch = useDispatch()
    const deleteOverlayAction = bindActionCreators(delete_overlay, dispatch)
    const getOverlaysAction = bindActionCreators(get_overlays, dispatch)

    useEffect(() => {getOverlaysAction()}, [])

    const deleteOverlay = async (id: number) => {
        await deleteOverlayAction(id, rooftops)
        await props.getCountFunction()
        await getOverlaysAction()
    }

    return (
        <div>
            {overlays.sort((a, b) => a.order - b.order).map((overlay, index) => (
                <OverlayCell key={index} overlays={overlays} mode={props.mode} modeFunction={props.modeFunction} resetFunction={props.resetFunction} setParametersFunction={props.setParametersFunction} loadBackgroundFunction={props.loadBackgroundFunction} loadImageFunction={props.loadImageFunction} vehicleType={overlay.body_type} img={overlay.img} id={overlay.id} deleteFunction={deleteOverlay} name={overlay.name} order={overlay.order} manditory={overlay.manditory} />
            ))}
        </div>
    )
}