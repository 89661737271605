import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { styled } from '@mui/system';

interface Props {
  label?: string,
  idx?: number,
  checked: boolean,
  onChange: any
}

export default function ToggleSwitch(props: Props) {
  const StyledSwitch = styled((props: Props) => (
    <Switch {...props} />
  ))(({ theme }) => ({
    padding: 7,
    '& .MuiSwitch-switchBase': {
      transitionDuration: '1000ms',
      '&.Mui-checked': {
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },
      '& .MuiSwitch-thumb': {
        width: 18,
        height: 18,
        bottom: -1,
        right: -1
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: 12,
      backgroundColor: theme.palette.grey[600],
      transition: (theme:any) => theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    }
  }))

    return (
      props.label 
      ?
      <FormGroup>
        <FormControlLabel control={<StyledSwitch checked={props.checked} onChange={props.onChange} />} label={props.label} />
      </FormGroup>
      :
      <StyledSwitch checked={props.checked} onChange={props.onChange} />
    )
}