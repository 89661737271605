import { ImageActions } from "./action_types"
import { Action } from "./actions"

const imagesReducer = (
  initialState: any,
  action: Action
): any => {
  let currentState;

  switch (action.type) {
    case ImageActions.EXTERIOR_STORE:
      let state = { 'exterior': action.payload }

      return {
        ...state,
      }

    case ImageActions.INTERIOR_STORE:
      currentState = JSON.parse(JSON.stringify(initialState));
      currentState['interior'] = action.payload

      return {
        ...currentState,
      }

    case ImageActions.MANUAL_STORE:
      currentState = JSON.parse(JSON.stringify(initialState));

      if (!("manual" in currentState)) {
        let images = [];
        images.push(action.payload)
        currentState["manual"] = images;
      } else {
        currentState.manual.push(action.payload)
      }

      return {
        ...currentState,
      }

    case ImageActions.DELETE:
      let removeCurrentState = JSON.parse(JSON.stringify(initialState));
      removeCurrentState.manual.splice(action.payload, 1)
      return {
        ...removeCurrentState,
      }

    case ImageActions.UPDATE:
      let update = JSON.parse(JSON.stringify(initialState));
      update[action.index] = action.payload

      return {
        ...update,
      }

    default:
      return {
        ...initialState,
      }
  }
}

export default imagesReducer;