import { Alert, Autocomplete, Grid, MenuItem, Select, TextField } from '@mui/material';
import ImageEditor from '@toast-ui/react-image-editor'
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Vehicle } from 'shared/interfaces/vehicle.interface';
import { State } from 'state/reducers';
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css'
import './overlay-canvas.css'
import { OverlayTable, OverlayDTO } from './table/OverlayTable';
import { PastePopUp } from './Paste64PopUp';
import { SnackBar } from './SnackBar';
import DropMenu from './DropMenu';
import { bindActionCreators } from 'redux';
import { update_overlay, create_overlay } from 'state/overlays/action_creator';

interface OverlayOptions {
    mandatory: boolean,
    vehicleType: string,
    imageId: number,
    selection: string,
    name: string,
    imageSet: any,
    srcImage: string,
    imageType: string
}

interface FileImport {
    raw: any,
    base64: string
}

enum Mode {
    Draw,
    Edit
}

// @ts-nocheck
const OverlayCanvas = () => {
    const overlays = useSelector((state: State) => state.overlays.overlays) as OverlayDTO[]
    const dispatch = useDispatch()
    const [savedOverlays, setSavedOverlays] = useState<OverlayDTO[]>([]);
    const [imageIndex, setImageIndex] = useState(0)
    const [allVehicles, setAllVehicles] = useState<Vehicle[]>([])
    const [invalidName, setInvalidName] = useState(false);
    const [overlayCount, setOverlayCount] = useState(0);
    const [showingBackground, setShowingBackground] = useState(true);
    const [bodyTypes, setBodyTypes] = useState<string[]>([]);
    const [importedFile, setImportedFile] = useState<FileImport>({
        raw: null,
        base64: ''
    });
    const [mode, setMode] = useState<Mode>(Mode.Draw);
    const [showingPaste, setShowingPaste] = useState(false);
    const [copied, setCopied] = useState(false);
    const updateOverlay = bindActionCreators(update_overlay, dispatch)
    const createOverlay = bindActionCreators(create_overlay, dispatch)

    const [overlayOptions, setOverlayOptions] = useState<OverlayOptions>({
        mandatory: true,
        vehicleType: '',
        imageId: 0,
        selection: '',
        name: '',
        imageSet: null,
        srcImage: '',
        imageType: ''
    })

    const imageEditorRef = useRef<any>(null)
    const myTheme = {}
    const inputFile = useRef<any>(null)

    useEffect(() => {
        // new way of fetching vehicles
        getVehicles().then((vehicles: Vehicle[]) => {
            setAllVehicles(vehicles)
        })
        getImages();
        getOverlayCount();
        getAllBodyTypes();
    }, [overlayOptions.imageId, overlayOptions.selection])

    useEffect(() => {
        handleImport()
    }, [importedFile, overlayOptions.imageId])

    const getVehicles = async () => {
        const vehicles = await axios.get('/api/vehicles')
        return vehicles.data
    }

    const handleImport = () => {
        if (importedFile.base64 == '' && importedFile.raw != null) {
            try {
                convertImageBase64(importedFile.raw)
            } catch (err: any) {
                console.log(err)
            }
        } else if (importedFile.base64 != '' && importedFile.raw == null) {
            getInstance().addImageObject(importedFile.base64).then((res: any) => {
                console.log('image object added')

                setImportedFile({
                    raw: null,
                    base64: ''
                })
            }).catch((err: any) => {
                console.log(err)
            })
        }
    }

    const getOverlayCount = async () => {
        setOverlayCount(overlays.length)
    }

    const convertImageBase64 = (file: any) => {
        if (hasBackgroundImage()) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let result: string = reader.result!.toString()
                setImportedFile({
                    raw: file,
                    base64: result
                })

                getInstance().addImageObject(result).then((res: any) => {
                    console.log('image added')

                    setImportedFile({
                        raw: null,
                        base64: ''
                    })
                }).catch((err: any) => {
                    console.log(err)
                })
            }
            reader.onerror = (error) => {
                console.log(error);
                getInstance().ui.activeMenuEvent();
            }
        } else {
            alert('Please add a background image first.')
        }
    }

    const clearFields = () => {
        setOverlayOptions({ ...overlayOptions, mandatory: false, name: '', imageType: '' })
    }

    const getInstance = () => {
        return imageEditorRef.current?.getInstance();
    }

    const handleNameChange = (event: any) => {
        setOverlayOptions({ ...overlayOptions, name: event.target.value })
    }

    const getAllBodyTypes = async () => {
        let bodyTypes: string[] = []
        const overlays = await axios.get('/api/overlay')

        overlays.data.forEach((overlay: OverlayDTO) => {
            if (!bodyTypes.includes(overlay.body_type)) {
                bodyTypes.push(overlay.body_type)
            }
        })

        setBodyTypes(bodyTypes)
    }

    const saveImage = () => {
        setOverlayCount(overlays.length)

        if (mode == Mode.Edit) {
            setMode(Mode.Draw)
        }

        if (overlayOptions.name == '') {
            setInvalidName(true);
            return;
        }

        getInstance().applyFilter('Brightness', { 'brightness': 100 }).then((res: any) => {
            getInstance().applyFilter('removeColor', {
                color: '#FFFFFF'
            }).then(() => {
                let dataUrl = getInstance().toDataURL();
                let overlayName: string = overlayOptions.name;

                let overlay: OverlayDTO = {
                    id: savedOverlays.length + 1,
                    img: dataUrl,
                    manditory: true,
                    name: overlayName,
                    order: overlayCount + 1,
                    body_type: overlayOptions.vehicleType,
                    sub_type: overlayOptions.imageType != '' ? overlayOptions.imageType.toLowerCase() : undefined,
                    phone_camera_lens: 'wide'
                }

                setSavedOverlays(current => [...current, overlay])

                if (overlays.find((overlay: OverlayDTO) => overlay.name == overlayName)) {
                    const foundOverlay = overlays.find((overlay: OverlayDTO) => overlay.name == overlayName) as OverlayDTO;
                    overlay.order = foundOverlay.order
                    updateOverlay(overlay)
                } else {
                    createOverlay(overlay)
                    setSavedOverlays([])
                    getInstance().clearUndoStack();
                }

                getOverlayCount().then(() => {
                    reset();
                })
            })
        })

        setInvalidName(false);
    }

    const getImages = async () => {
        if (overlayOptions.selection != '') {
            const vehicle = allVehicles.find((vehicle: Vehicle) => vehicle.vin == overlayOptions.selection)
            const foundImages = await axios.get('/api/file/get_files/?vehicle_id=' + vehicle!.id)
            setOverlayOptions({ ...overlayOptions, imageSet: foundImages.data })
        }
    }

    const handleBackground = (action: string, callback: () => void) => {
        if (action == 'add') {
            getInstance().applyFilter('Brightness', { 'brightness': 100 }).then((res: any) => {
                getInstance().applyFilter('removeColor', {
                    color: '#FFFFFF'
                }).then((res: any) => {
                    callback();
                }).catch((err: any) => {
                    console.log(err)
                })
            }).catch((err: any) => {
                console.log(err)
            })
        }

        if (action == 'remove') {
            getInstance().removeFilter('Brightness', { 'brightness': 100 }).then((res: any) => {
                getInstance().removeFilter('removeColor', {
                    color: '#FFFFFF'
                }).then((res: any) => {
                    callback()
                }).catch((err: any) => {
                    console.log(err)
                })
            }).catch((err: any) => {
                console.log(err)
            })
        }
    }

    const toggleBackground = () => {
        if (showingBackground) {
            handleBackground('add', () => {
                setShowingBackground(false)
            })
        } else {
            handleBackground('remove', () => {
                setShowingBackground(true)
            })
        }
    }

    const formatName = (name: string) => {
        let splitName = name.split('/');
        return splitName[splitName.length - 1];
    }

    const handleChange = (event: any, value: any) => {
        try {
            getInstance().ui.activeMenuEvent();
        } catch (err: any) {
            console.log(err)
        }

        try {
            if (value.vin != null) {
                setOverlayOptions({ ...overlayOptions, selection: value.vin })
            }
        } catch (err: any) {
            console.log(err)
        }
    }

    const hasBackgroundImage = () => {
        const image = getInstance().getImageName()

        if (image != '') {
            return true
        }

        return false;
    }

    const loadImage = (image = null) => {
        try {
            if (image == undefined) {
                const displayImage = overlayOptions.imageId;

                if (displayImage != undefined) {
                    getInstance().loadImageFromURL(`data:image/jpeg;base64,${displayImage}`, 'overlay').then((res: any) => {

                    }).catch((err: any) => {
                        console.log(err)
                    })
                }
            } else {
                if (hasBackgroundImage()) {
                    getInstance().addImageObject(image).then((res: any) => {

                    }).catch((err: any) => {
                        console.log(err)
                    })
                } else {
                    alert('No background image loaded. Please load a background image first.')
                    clearFields()
                    setMode(Mode.Draw)
                }
            }
        } catch (err: any) {
            console.log(err)
        }
    }

    const handleImageChange = (event: any) => {
        setImageIndex(event.target.value)
    }

    const handleBodyTypeChange = async (event: any) => {
        if (event.target.value != null) {
            let vehicleType = event.target.value.toString().toLowerCase();
            setOverlayOptions({ ...overlayOptions, vehicleType: vehicleType })
            getAllBodyTypes();
        }
    }

    const handleBodyTypeSelect = (event: any, value: any) => {
        if (value != null || value != undefined) {
            setOverlayOptions({ ...overlayOptions, vehicleType: value })
        } else {
            setOverlayOptions({ ...overlayOptions, vehicleType: '' })
        }
    }

    const copyBase64 = () => {
        getInstance().applyFilter('Brightness', { 'brightness': 100 }).then((res: any) => {
            getInstance().applyFilter('removeColor', {
                color: '#FFFFFF'
            }).then((res: any) => {
                let dataUrl = getInstance().toDataURL();
                navigator.clipboard.writeText(dataUrl);

                getInstance().removeFilter('Brightness', { 'brightness': 100 }).then((res: any) => {
                    getInstance().removeFilter('removeColor', {
                        color: '#FFFFFF'
                    }).then(() => {
                        setCopied(true)
                        setTimeout(() => setCopied(false), 3000)
                    }).catch((err: any) => {
                        console.log(err)
                    })
                }).catch((err: any) => {
                    console.log(err)
                })
            }).catch((err: any) => {
                console.log(err)
            })
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const reset = () => {
        getInstance().removeFilter('Brightness', { 'brightness': 100 }).then((res: any) => {
            getInstance().removeFilter('removeColor', {
                color: '#FFFFFF'
            }).then((res: any) => {
                getInstance().clearObjects().then((res: any) => {
                    console.log('cleared')
                });
            })
        }).catch((err: any) => {
            getInstance().clearObjects();
        })

        clearFields();
    }

    const uploadImage = async () => {
        await inputFile.current.click()
    }

    const setParameters = (name: string, tag: string) => {
        setOverlayOptions({ ...overlayOptions, name: name, vehicleType: tag })
    }

    const loadBackground = (image: string, overlay: string, name: string, vehicleType: string) => {
        getInstance().addImageObject(overlay).then(() => {
            setOverlayOptions({ ...overlayOptions, srcImage: image, name: name, vehicleType: vehicleType })
        })
    }

    const handleImageTypeChange = (event: any) => {
        setOverlayOptions({ ...overlayOptions, imageType: event.target.value })
    }

    const handleLoadImage = async (id: number) => {
        try {
            const image = await axios.get('/api/file/get_file/?id=' + id)
            await getInstance().loadImageFromURL(`data:image/jpeg;base64,${image.data.data}`, 'overlay').then((res: any) => {
                setOverlayOptions({ ...overlayOptions, srcImage: image.data.data })
            }).catch((err: any) => {
                console.log(err)
            })
        }
        catch (err: any) {
            console.log(err)
        }
    }

    const menuItems = [
        {
            name: 'Toggle Background',
            onClick: toggleBackground,
            divider: true
        },
        {
            name: 'Copy Base64',
            onClick: copyBase64,
        },
        {
            name: 'Paste Base64',
            onClick: () => setShowingPaste(true),
            divider: true
        },
        {
            name: 'Reset',
            onClick: reset
        },
        {
            name: 'Import',
            onClick: async () => await uploadImage()
        },
        {
            name: 'Save',
            onClick: saveImage
        },
    ]

    return (
        <div>
            <PastePopUp setBase64Function={setImportedFile} open={showingPaste} setFunction={setShowingPaste} />
            <SnackBar linker={copied} />
            <DropMenu menuItems={menuItems} />
            <Grid container justifyContent='space-between' className='overlay-designer-container'>
                <Grid item xs={9}>
                    <Grid className='select-container'>
                        <Autocomplete sx={{ width: 50 + "%" }} disablePortal options={allVehicles} onChange={handleChange} getOptionLabel={(vehicle: Vehicle) => vehicle.vin} renderInput={(params: any) => <TextField {...params} label="vin" />}></Autocomplete>
                        <Select className='drop-down' sx={{ width: 49 + "%" }} value={imageIndex} onChange={handleImageChange}>
                            {overlayOptions.imageSet ? overlayOptions.imageSet.sort((a: any, b: any) => a.vehicle_id.toLowerCase().localeCompare(b.vehicle_id.toLowerCase())).map((image: any, idx: any) =>
                                <MenuItem key={idx} value={idx + 1} onClick={async () => await handleLoadImage(image.id)}>{`${formatName(image.path)}`}</MenuItem>
                            ) : null}
                        </Select>
                        <input onChange={(e: any) => setImportedFile({ raw: e.target.files[0], base64: '' })} type="file" id="file" ref={inputFile} style={{ display: 'none' }} />
                    </Grid>
                    <Grid className='select-container'>
                        {invalidName ? <Alert severity="error">Name is required</Alert> : null}
                        <TextField sx={{ width: 34 + "%" }} id="outlined-basic" label="name" variant="outlined" value={overlayOptions.name} onChange={handleNameChange} />
                        <Autocomplete sx={{ width: 34 + "%", marginLeft: 1 }} freeSolo disablePortal inputValue={overlayOptions.vehicleType} options={bodyTypes} onChange={handleBodyTypeSelect} onInputChange={handleBodyTypeChange} getOptionLabel={(bodyType: string) => bodyType} renderInput={(params: any) => <TextField {...params} label="body type" />}></Autocomplete>
                        <TextField sx={{ width: 34 + "%", marginLeft: 1 }} id="outlined-basic" label="Sub-type" variant="outlined" value={overlayOptions.imageType} onChange={handleImageTypeChange} />
                    </Grid>
                    <Grid>
                        <ImageEditor
                            ref={imageEditorRef}
                            includeUI={{
                                loadImage: {
                                    path: '',
                                },
                                theme: myTheme,
                                menu: ['shape', 'draw'],
                                initMenu: 'filter',
                                uiSize: {
                                    width: '100%',
                                    height: '800px',
                                },
                                menuBarPosition: 'top',
                                willReadFrequently: true,
                            }}
                            selectionStyle={{
                                cornerSize: 20,
                                rotatingPointOffset: 70,
                            }}
                            usageStatistics={false}></ImageEditor>
                    </Grid>
                </Grid>
                <Grid item xs={3} className='saved-overlay-container'>
                    <OverlayTable getCountFunction={getOverlayCount} mode={mode} modeFunction={setMode} resetFunction={reset} overlayOptions={overlayOptions} setParametersFunction={setParameters} loadBackgroundFunction={loadBackground} loadImageFunction={loadImage} overlays={savedOverlays} />
                </Grid>
            </Grid>
        </div>
    )
}

export default OverlayCanvas;