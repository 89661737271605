import Menu from './Menu';
import { Outlet } from 'react-router-dom';

export default function Settings() {

  return (
    <div className='col mb-3'>
      <Menu />
      <div className='mt-4'>
        <Outlet />
      </div>
    </div>
  )
}