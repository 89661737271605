
import OverlayCanvas from "./canvas/OverlayCanvas"
import "./designer-page.css"
import SubTabs from "components/shared/SubTabs"
import AssignOverlayContainer from "./assign/AssignOverlayContainer"

export default function OverlayDesigner() {
    const tabs = [
        { label: "Canvas", component: <OverlayCanvas /> },
        { label: "Assign", component: <AssignOverlayContainer /> }
    ]

    return (
        <SubTabs tabs={tabs} />
    )
}