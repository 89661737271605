import { Rooftop } from "shared/interfaces/rooftop.interface";
import { OverlayActions } from "./action_types"
import { Action } from "./actions"
import { OverlayDTO, RooftopOverlay } from "components/admin/settings/overlay/canvas/table/OverlayTable";

const overlayReducer = (state: any, action: Action): any => {
  switch (action.type) {
    case OverlayActions.CREATE:
      return {
        ...state,
        overlays: [...state.overlays, action.payload]
      }

    case OverlayActions.UPDATE:
      const updatedoverlays = state.overlays.map((overlay: OverlayDTO) =>
        overlay.id === action.payload.id ? action.payload : overlay
      );
      return {
        ...state,
        overlay: action.payload,
        overlays: updatedoverlays
      };

    case OverlayActions.GET:
      return {
        ...state,
        overlay: action.payload
      }

    case OverlayActions.GET_ALL:
      return {
        ...state,
        overlays: action.payload
      }

    case OverlayActions.DELETE:
      return {
        ...state,
        overlays: [...state.overlays.filter((overlay: any) => overlay.id !== action.payload)]
      }

    default:
      return {
        ...state,
      }
  }
}

export default overlayReducer
