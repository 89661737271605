import { Button, Checkbox, FormControlLabel, Paper } from "@mui/material";
import "./dropbox.css"

interface DropBoxProps {
    module: any,
    updateEnabled: (module: any) => void
}

function DropBox(props: DropBoxProps) {
    return (
        <Paper className="draggable-container" elevation={3}>
            <p className="draggable-order">{props.module.order}.</p>
            <p className="draggable-name">{props.module.name} </p>
            <Button className="draggable-button" variant="outlined" onClick={() => props.updateEnabled(props.module)}>Disable</Button>
        </Paper>
    )
}

export default DropBox;