import { FormValidationActions } from "./action_types"
import { Action } from "./actions"

const formValidationReducer = (state: any, action: Action): any => {
  switch (action.type) {
    case FormValidationActions.SET:

      return {
        ...action.payload
      }

    case FormValidationActions.RESET:
      return {
        
      }

    default:
      return {
        ...state,
      }
  }
}

export default formValidationReducer
