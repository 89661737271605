import { AuthActions } from "./action_types"
import { Action } from "./actions"

const state = {
  failed: false, authorized: false
}
const authReducer = (
  initialState: any = state,
  action: Action
): any => {
  switch (action.type) {
    case AuthActions.LOGIN:
      return {
        failed: false,
        authorized: action.payload
      }

    case AuthActions.LOGOUT:
      return {
        failed: false,
        authorized: false
      }

    case AuthActions.FAILED:
      return {
        authorized: false,
        failed: true
      }
      
    default:
      return {
        ...initialState,
      }
  }
}

export default authReducer