import { Divider, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Rooftop } from 'shared/interfaces/rooftop.interface';
import BWTextField from 'components/shared/BWTextInput';
import BWCheckbox from 'components/shared/BWCheckbox';

interface PhoneSyncSettingsProps {
  rooftopState: Rooftop;
  handleRooftopChange: (key: string, value: any) => void;
}

const PhoneSyncSettings = ({
  rooftopState,
  handleRooftopChange,
}: PhoneSyncSettingsProps): ReactElement => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>Phone and Tablet Sync Settings</Typography>
      <Divider />
      <br />
      <BWCheckbox
        value={rooftopState.phone_sync}
        onChange={() => handleRooftopChange('phone_sync', !rooftopState.phone_sync)}
        label='Phone and Tablet Sync'
      />
      <BWTextField
        label='Phone Sync Terminology'
        onChange={(value) => handleRooftopChange("phone_sync_terminology", value)}
        value={rooftopState.phone_sync_terminology}
      />
    </div>
  );
};

export default PhoneSyncSettings;
