import { ThemeActions } from "./action_types";
import { Action } from "./actions";

const themeReducer = (state: any, action: Action): any => {
    switch (action.type) {
        case ThemeActions.SET:
            return {
                darkTheme: action.darkTheme
            }

        case ThemeActions.TOGGLE:
            return {
                darkTheme: !state.darkTheme
            }

        default:
            return {
                ...state
            }
    }
}

export default themeReducer