import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Slider, Stack, Box, Typography, Paper } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Magnifier from "react-magnifier";
import { useNavigate } from "react-router-dom";

interface VehicleDisplayProps {
    uuid: string;
}

export default function VehicleDisplay({ uuid }: VehicleDisplayProps) {
    const [images, setImages] = useState<any[]>([]);
    const [viewSelection, setViewSelection] = useState<string>("all");
    const navigate = useNavigate();
    const magnifierConfig = { width: 200, height: 200 };

    useEffect(() => {
        const fetchImages = async () => {
            const response = await axios.get(`/api/vehicles/get_images/?image_type=all&vehicle_id=${uuid}`);
            setImages(response.data);
        };
        fetchImages();
    }, [uuid]);

    const handleViewChange = (event: SelectChangeEvent) => {
        setViewSelection(event.target.value as string);
    };

    const openImage = (image: any) => {
        // Create an Image object to get the natural width and height
        const img = new Image();
        img.src = `data:image/jpeg;base64,${image.img}`;
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          const popupWidth = 1024; // Default width
          const popupHeight = popupWidth / aspectRatio;
          const newWindow = window.open('', 'Image', `width=${popupWidth},height=${popupHeight}`);
          newWindow?.document.write(`<img src="data:image/jpeg;base64,${image.img}" alt="Image" width="${popupWidth}" height="${popupHeight}"/>`);
        };
      };

    const renderImages = (filter: string) => (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
                justifyContent: 'center'
            }}
        >
            {images.filter(image => filter === "all" || image.type === filter).map(image => (
                <Box
                    key={image.id}
                    onClick={() => openImage(image)}
                    sx={{
                        cursor: 'pointer',
                        textAlign: 'left',
                        mb: 2,
                        width: `32%`,
                        maxWidth: '100%',
                    }}
                >
                    <Magnifier
                        className="shadow-lg"
                        src={`data:image/jpeg;base64,${image.img}`}
                        mgShape='square'
                        zoomFactor={2}
                        mgHeight={magnifierConfig.height}
                        mgWidth={magnifierConfig.width}
                    />
                </Box>
            ))}
        </Box>
    );

    return (
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
            <Stack spacing={4}>
                <FormControl fullWidth>
                    <InputLabel id="view-select-label">View Selection</InputLabel>
                    <Select
                        labelId="view-select-label"
                        id="view-select"
                        value={viewSelection}
                        label="Image Type"
                        onChange={handleViewChange}
                    >
                        <MenuItem value="exterior">Exterior</MenuItem>
                        <MenuItem value="interior">Interior</MenuItem>
                        <MenuItem value="manual">Manual</MenuItem>
                        <MenuItem value="all">All</MenuItem>
                    </Select>
                </FormControl>

                {images ? renderImages(viewSelection) : <Typography variant="h4">Loading...</Typography>}

                <Box textAlign="left" pt={2}>
                    <Button variant="contained" color="primary" onClick={() => navigate('/admin/vehicle')}>
                        Exit
                    </Button>
                </Box>
            </Stack>
        </Paper>
    );
}
