import { CameraActions } from "./action_types"
import { Action } from "./actions"
import { Camera } from "shared/interfaces/camera.interface"

const cameraReducer = (
  initialState: any,
  action: Action
): any => {
  switch (action.type) {
    case CameraActions.CREATE:
      let addedCameras = parseCameraState(initialState)
      addedCameras.cameras.push(action.payload)

      return {
        ...addedCameras,
      }

    case CameraActions.UPDATE:
      let existingCameras = parseCameraState(initialState)
      let updatedCameraIndex = findCameraById(initialState.cameras, action.payload.id)

      existingCameras.cameras[updatedCameraIndex] = action.payload

      return {
        ...initialState,
        cameras: existingCameras.cameras
      }

    case CameraActions.LIST:
      return {
        ...initialState,
        cameras:action.payload
      }

    case CameraActions.REORDER:
      return {
        ...initialState,
        cameras:action.payload
      }

    case CameraActions.DELETE:
      let removedCameras = parseCameraState(initialState)
      const deletedCameraIndex = findCameraById(initialState.cameras, action.id)
      
      if (deletedCameraIndex || deletedCameraIndex == 0) {
        removedCameras.cameras.splice(deletedCameraIndex,1);
      }
      
      return {
        ...removedCameras,
      }
    
    case CameraActions.BARCODE:
      return {
        ...initialState,
        barcode:action.barcode
      }
    
    default:
      return {
        ...initialState,
      }
  }
}

const findCameraById = (cameras: any, id:number) => {
  return cameras.findIndex((camera: Camera) => camera.id === id);
}

const parseCameraState = (cameras:any) => {
  return JSON.parse(JSON.stringify(cameras));
}

export default cameraReducer