import { Button, Divider, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.css';

export default function Menu() {
  const location = useLocation()
  const navigateTo = useNavigate()

  const tabs = [
    {
      title: 'Rooftop Details',
      link: 'rooftop',
    },
    {
      title: 'Camera Settings',
      link: 'camera',
    },
    {
      title: 'Overlay Designer',
      link: 'overlay',
    },
  ]

  const handleButtonClick = (link: string) => navigateTo(link)

  return (
    <div>
      <Grid container direction="row">
        {
          tabs.map((tab) => (
            <Grid item key={tab.link} xs={6} sm={4} md={2} textAlign="center" alignContent="center">
              <Button
                key={tab.title}
                onClick={() => handleButtonClick(tab.link)}
                className={`button ${location.pathname.includes(tab.link) ? 'selected' : ''}`}
              >
                {tab.title}
              </Button>
            </Grid>
          ))
        }
      </Grid>
      <Divider />
    </div>
  )
}
