import { Checkbox, FormControlLabel, TextField } from "@mui/material";

interface Props {
    onChange: (e: any) => void
    label: string
    value: boolean
}

export default function BWTextField(props: Props) {

    return (
        <div className="mb-2">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.value}
                        onChange={(e: any) => props.onChange(e)}
                    />
                }
                label={props.label}
            />
        </div>
    )
}
