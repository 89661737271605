import axios from "axios";

const getModules = async () => {
    return await axios.get('/api/module/');
}

const createModule = async (module: any) => {
    return await axios.post('/api/module/', module);
}

const updateModule = async (module: any) => {
    return await axios.put(`/api/module/${module.id}/`, module);
}

export { getModules, createModule, updateModule }