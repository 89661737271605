import { Dispatch } from "redux";
import { Action } from "./actions";
import { AuthActions } from "./action_types";

export const login = (password: string) => {
  return async (dispatch: Dispatch<Action>) => {
    if (password === "3I.Blackwidow") {
      dispatch({
        type: AuthActions.LOGIN,
        payload: true
      })
    } else {
      dispatch({
        type: AuthActions.FAILED,
      })
    }

  }
}

export const logout = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: AuthActions.LOGOUT,
    })
  }
}
