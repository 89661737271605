import SideNav from './SideNav';
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';
import { State } from 'state/reducers';
import { Grid } from '@mui/material';

export default function Admin() {
  const authorized = useSelector((state: State) => state.auth.authorized);

  if (authorized) {
    return (
      <Grid container display='flex' flexDirection={'column'}>
        <Grid>
          <SideNav />
        </Grid>
        
        <Grid marginLeft={'6rem'} marginTop='1rem'>
          <Outlet />
        </Grid>
      </Grid>
    )
  } else {
    return <Navigate to="/login" replace />
  }
}