import { Grid, styled } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';

const icons = [
  { route: 'vehicle', icon: <HomeIcon fontSize='large' /> },
  { route: 'settings', icon: <SettingsIcon fontSize='large' /> },
];

const StyledSideNav = styled('div')(({ theme }) => ({
  borderLeft: 'solid transparent',
  borderLeftWidth: '.5rem',
  color: theme.palette.grey[700],
  '&.active': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected
  },
  ':hover': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export default function SideNav() {
  const location = useLocation();

  return (
    <Grid textAlign='center' sx={{ position:'fixed', backgroundColor: 'white', height: 'calc(100vh - 51px)', width: '4.5rem' }}>
      {icons.map((item, index) => (
        <Link to={{ pathname: item.route }} key={index}>
          <StyledSideNav className={location.pathname.includes(item.route) ? 'active py-3 mt-2' : 'py-3 mt-2'}>
            {item.icon}
          </StyledSideNav>
        </Link>
      ))}
      </Grid>
  );
}
