import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from "@mui/material";
import { useEffect, useState } from "react";

interface PastePopUpProps {
    setFunction: any
    open: boolean
    setBase64Function: any
}

export function PastePopUp(props: PastePopUpProps) {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        props.setFunction(false)
    };

    const handleCancel = () => {
        props.setBase64Function({
            base64: '',
            raw: null
        })
        props.setFunction(false)
    }

    useEffect(() => {
        if(props.open) {
            setOpen(true)
        }

        if(!props.open) {
            setOpen(false)
        }
    }, [props.open])

    const handleChange = (event: any) => {
        props.setBase64Function({
            base64: event.target.value,
            raw: null
        })
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Paste Base64</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Base 64 String"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleChange}
                        multiline
                        rows={4}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Accept</Button>
                    <Button onClick={handleCancel}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}