import { OverlayActions } from "./action_types";
import { Dispatch } from "redux";
import { Action as OverlayAction } from "./actions";
import axios from "axios";
import { OverlayDTO } from "components/admin/settings/overlay/canvas/table/OverlayTable";
import { Rooftop } from "shared/interfaces/rooftop.interface";
import { delete_overlay_from_rooftops } from "state/rooftop/action_creator";

export const update_overlay = (overlay: OverlayDTO) => {
  return async (dispatch: Dispatch<OverlayAction>) => {
    return axios.put(`/api/overlay/${overlay.id}/`, overlay)
      .then(response => {
        dispatch({
          type: OverlayActions.UPDATE,
          payload: response.data
        })
        return response
      })
  }
}

export const create_overlay = (overlay: OverlayDTO) => {
  return async (dispatch: Dispatch<OverlayAction>) => {
    return axios.post('/api/overlay/', overlay)
      .then(response => {
        dispatch({
          type: OverlayActions.CREATE,
          payload: response.data
        })
      })
  }
}

export const delete_overlay = (overlayId: number, rooftops: Rooftop[]) => {
  return async (dispatch: Dispatch<OverlayAction>) => {
    delete_overlay_from_rooftops(rooftops, overlayId)(dispatch)
    return axios.delete(`/api/overlay/${overlayId}/`)
      .then(response => {
        dispatch({
          type: OverlayActions.DELETE,
          payload: overlayId
        })
      })
    }
}

export const get_overlays = () => {
  return async (dispatch: Dispatch<OverlayAction>) => {
    return axios.get('/api/overlay/')
      .then(response => {
        dispatch({
          type: OverlayActions.GET_ALL,
          payload: response.data as OverlayDTO[]
        })
      })
    
  }
}

export const get_overlay = (overlay: OverlayDTO) => {
  return async (dispatch: Dispatch<OverlayAction>) => {
    dispatch({
      type: OverlayActions.GET,
      payload: overlay
    })
  }
}