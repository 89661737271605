import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import Admin from 'components/admin/Admin';
import CameraSettings from 'components/admin/settings/camera/CameraSettings';
import Login from 'components/Login';
import ModuleForm from 'components/admin/settings/modules/ModuleForm';
import OverlayDesigner from 'components/admin/settings/overlay/OverlayDesigner';
import RooftopSelector from 'components/admin/settings/rooftop/RooftopSelector';
import VehicleInfo from 'components/admin/settings/vehicles/VehicleInfo';
import VehiclePage from 'components/admin/settings/vehicles/VehiclePage';
import VehicleSearch from 'components/admin/settings/vehicles/VehicleSearch';
import Settings from 'components/admin/settings/Settings';
import './index.css';
import './shared/utils/response-interceptor';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import store from './state/store';


// Sentry.init({
//   dsn: 'https://7642d0b34eab4a6db5c78d1e11f24747@o4504810778722304.ingest.sentry.io/4504833189740544',
//   integrations: [new BrowserTracing()],
//   environment: process.env.REACT_APP_ENV,
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="admin" element={<Admin />}>
              <Route path="" element={<Navigate to="vehicle" />} />
              <Route path="vehicle" element={<VehicleSearch />}>
                <Route path="" element={<VehiclePage />} />
                <Route path=":uuid" element={<VehicleInfo />} />
              </Route>
              <Route path="settings" element={<Settings />}>
                <Route path="rooftop" element={<RooftopSelector />} />
                <Route path="camera" element={<CameraSettings />} />
                <Route path="overlay" element={<OverlayDesigner />} />
                <Route path="modules" element={<ModuleForm />} />
                <Route path="" element={<Navigate to="rooftop" />} />
                <Route path="*" element={<Navigate to="rooftop" />} />
              </Route>
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
