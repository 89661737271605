import { TextField } from "@mui/material";
import { useEffect, useState, useRef } from "react";

interface Props {
  onChange: (e: any) => void;
  label: string;
  value: string | null;
}

export default function BWTextField(props: Props) {
  const [error, setError] = useState<string | null>(null);
  const [value, setValue] = useState<string | null>(props.value);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleChange = (info: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (info !== props.value) {
      timeoutRef.current = setTimeout(() => {
        props.onChange(info);
      }, 1000);
    }
  };

  useEffect(() => {
    if (props.label === "URL") {
      if (!value?.startsWith("https://")) {
        setError("URL must start with https://");
      } else {
        setError(null);
        handleChange(value?.trim() || '');
      }
    } else {
      handleChange(value?.trim() || '');
    }
  }, [value, props.label]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <TextField
      fullWidth
      inputMode="text"
      InputLabelProps={{
        style: {
          fontSize: "1.4rem",
          color: "black",
        },
      }}
      label={props.label}
      style={{
        backgroundColor: "#e9e9e9",
        marginBottom: "20px",
      }}
      error={!!error}
      helperText={error}
      onChange={(e) => setValue(e.target.value)}
      value={value ?? ""}
    />
  );
}
