import { TextField } from "@mui/material";
import { useEffect, useState, useRef } from "react";

interface Props {
  onChange: (e: number | null) => void;
  label: string;
  value: number | null;
}

export default function BWNumberField(props: Props) {
  const [value, setValue] = useState<number | null>(props.value);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    const newValue = e.target.value.replace(/\D/g,'');
    if (/^\d*\.?\d*$/.test(newValue)) {
      const numValue = e.target.value === '' ? 0 : parseInt(e.target.value)
      setValue(numValue)
      timeoutRef.current = setTimeout(() => {
        props.onChange(numValue);
      }, 1000);
    }
  };

  return (
    <TextField
      fullWidth
      inputMode="numeric"
      InputLabelProps={{
        style: {
          fontSize: '1.4rem',
          color: 'black',
        },
      }}
      label={props.label}
      style={{
        backgroundColor: "#e9e9e9",
        marginBottom: "20px",
      }}
      onChange={handleChange}
      value={value ?? ''}
    />
  );
}
