import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Camera } from "shared/interfaces/camera.interface";
import { Rooftop } from "shared/interfaces/rooftop.interface";
import { State } from "state/reducers";
import { update_rooftop } from "state/rooftop/action_creator";

interface Props {
  onChange: (e: Rooftop[]) => void;
  camera: Camera
}

export default function CamFormRooftops(props: Props) {
  const dispatch = useDispatch()
  const update = bindActionCreators(update_rooftop, dispatch)
  
  let rooftops = useSelector((state: State) => state.rooftop.rooftops)

  const cameraHasRooftop = (rooftop: Rooftop) => {
    return rooftop.cameras.includes(props.camera.id)
  }


  const handleRooftopChange = (rooftop: Rooftop, event: any) => {
    const newCameras = event.target.checked ? [...rooftop.cameras, props.camera.id] : rooftop.cameras.filter((camId) => camId !== props.camera.id)
      
    update({
      ...rooftop,
      cameras: newCameras
    })
  }

  return (
    <>
      <Grid item xs={4}>
        <Typography variant="subtitle1" fontWeight="bold">
          Rooftops:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        {
          rooftops.map((rooftop: Rooftop) =>
            <div key={rooftop.id}>  
              <FormControlLabel
                onChange={(e) => handleRooftopChange(rooftop, e)}
                control={
                  <Checkbox checked={cameraHasRooftop(rooftop)} />
                }
                label={rooftop.name} />
              <br />
            </div>
          )
        }
      </Grid>
    </>
  );
}
