import { AlertActions } from "./action_types"
import { Action } from "./actions"

const alertReducer = (state: any, action: Action): any => {
  switch (action.type) {
    case AlertActions.SHOW:
      return {
        ...state,
        class: action.class,
        message: action.message,
        show: action.show,
      }

    case AlertActions.HIDE:
      return {
        ...state,
        show: false
      }


    default:
      return {
        ...state,
      }
  }
}

export default alertReducer
