import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../../../state/reducers'
import { bindActionCreators } from 'redux';
import { update_rooftop } from 'state/rooftop/action_creator';
import { useEffect, useState } from 'react';
import { Rooftop } from "shared/interfaces/rooftop.interface";
import WizardSettings from 'components/admin/settings/rooftop/wizard-settings/WizardSettings';
import axios from 'axios';
import PhoneCameraSettings from './PhoneCameraSettings';
import LegFoldSettings from './LegFoldSettings';
import AutoscanSettings from './AutoscanSettings';
import PhoneSyncSettings from './PhoneSyncSettings';
import RooftopSettings from './RooftopSettings';
import RooftopPreferences from './RooftopPreferences';
import SubTabs from 'components/shared/SubTabs';
import OverlaySettings from './OverlaySettings';

interface RooftopDetailsProps {
  rooftopId: number
}

export default function RooftopDetails({ rooftopId }: Readonly<RooftopDetailsProps>) {
  const dispatch = useDispatch()
  const update = bindActionCreators(update_rooftop, dispatch)

  const rooftops = useSelector((state: State) => state.rooftop.rooftops)
  const rooftop = rooftops.find((rooftop: Rooftop) => rooftop.id === rooftopId)

  const [serverVersion, setServerVersion] = useState('')

  const handleRooftopChange = (key: string, value: any) => {
    const newRooftopState = {
      ...rooftop,
      [key]: value
    }

    console.log("Updating rooftop!")
    update(newRooftopState)
  };

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await axios.get("/api/rooftop/get_version")
        setServerVersion(response.data)
      } catch (err: any) {
        setServerVersion("Could not fetch server version.")
      }
    }

    fetchVersion().catch(console.error)
  }, [])

  const tabs = [
    {
      label: "Settings",
      component:
        <RooftopSettings
          rooftopState={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Auto Scan",
      component:
        <AutoscanSettings
          rooftopState={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Leg Fold",
      component:
        <LegFoldSettings
          rooftopState={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Phone Camera",
      component:
        <PhoneCameraSettings
          rooftopState={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Phone Sync",
      component:
        <PhoneSyncSettings
          rooftopState={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Preferences",
      component:
        <RooftopPreferences
          rooftop={rooftop}
          handleRooftopChange={handleRooftopChange}
        />
    },
    {
      label: "Wizard",
      component:
        <WizardSettings rooftop={rooftop} />
    },
    {
      label: "Overlay Order",
      component:
        <OverlaySettings rooftop={rooftop} />
    }
  ]

  return (
    <>
      <div className='mb-3 border-b-2'>
        <SubTabs tabs={tabs} />
      </div>
      <div style={{ paddingTop: '1%' }}>{`Version: ${serverVersion}`}</div>
    </>
  )
}
