import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import { pink } from '@mui/material/colors';

function OnlineInidcator() {
  return (
    navigator.onLine
      ?
      <WifiIcon fontSize="large" color="success"/>
      :
      <WifiOffIcon fontSize="large" sx={{ color: pink[500] }} />
  )
}

export default OnlineInidcator