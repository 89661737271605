import { Paper, FormControlLabel, Checkbox, Button, FormGroup } from "@mui/material";
import { OverlayDTO, RooftopOverlay } from "../canvas/table/OverlayTable";
import "./draggable-box.css"
import { useDispatch, useSelector } from "react-redux";
import { State } from "state/reducers";
import { Rooftop } from "shared/interfaces/rooftop.interface";
import { bindActionCreators } from "redux";
import { update_rooftop } from "state/rooftop/action_creator";
import { delete_overlay, get_overlays } from "state/overlays/action_creator";

interface AssignOverlayProps {
  overlay: OverlayDTO
}

export default function AssignOverlayCard(props: AssignOverlayProps) {
  let rooftops = useSelector((state: State) => state.rooftop.rooftops)
  const dispatch = useDispatch()
  const update = bindActionCreators(update_rooftop, dispatch)
  const getOverlays = bindActionCreators(get_overlays, dispatch)
  const deleteOverlay = bindActionCreators(delete_overlay, dispatch)

  const handleRooftopChange = (rooftop: Rooftop, event: any) => {
    if (event.target.checked) {
      const overlayToPush = {
        id: props.overlay.id,
        manditory: false,
        phone_camera_lens: "wide",
        order: rooftop.overlays.length
      } as RooftopOverlay
      rooftop.overlays.push(overlayToPush)
      update(rooftop)
    } else {
      const updatedOverlays = rooftop.overlays.filter(rtOverlay => rtOverlay.id !== props.overlay.id)
      const updatedRooftop = {...rooftop, overlays: updatedOverlays}
      
      update(updatedRooftop)
    }
  }

  const handleDelete = async () => {
    await deleteOverlay(props.overlay.id, rooftops)
    await getOverlays()
  }

  const rooftopHasOverlay = (rooftop: Rooftop) => {
    return rooftop.overlays.find((rtOverlay) => rtOverlay.id === props.overlay.id) ? true : false
  }

  return (
    <div>
      <Paper className="draggable-container" elevation={3}>
        <p className="draggable-name">{props.overlay.name} </p>
        <p className="draggable-name">{props.overlay.sub_type !== null ? `sub-type: ${props.overlay.sub_type}` : null}</p>
        <img className="draggable-image" src={props.overlay.img}></img>
        <Button className="draggable-text" onClick={async () => await handleDelete()}>Delete</Button>
        <FormGroup>
          <div className="draggable-rooftop-display">
            {
              rooftops.map((rooftop: Rooftop, idx: number) =>
                <div>
                  <FormControlLabel onChange={(e) => handleRooftopChange(rooftop, e)} control={<Checkbox checked={rooftopHasOverlay(rooftop)} />} label={rooftop.name} />
                  <br />
                </div>
              )
            }
          </div>
        </FormGroup>
      </Paper>
    </div>
  )
}