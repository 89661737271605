import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Pagination, Select, TextField, Typography } from "@mui/material";
import axios from "axios";
import VehicleGrid from "./VehicleGrid";
import { useEffect, useState } from "react";

export default function VehiclePage() {
    const [page, setPage] = useState<number>(1)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [vehicles, setVehicles] = useState<any[]>([])
    const [totalResults, setTotalResults] = useState<any>(10)
    const [totalVehicles, setTotalVehicles] = useState<number>(0)
    const [search, setSearch] = useState<string>("")
    const [showUnpushed, setShowUnpushed] = useState<boolean>(false)

    const getVehicles = async () => {
        let url = `/api/vehicles/get_vehicle_page?page=${page}&results=${totalResults}`
        if (showUnpushed){ url += "&unpushed=true" }
        if (search) { url +=`&search=${search}` }

        const result = await axios.get(url)
        const vehiclesData = result.data    

        setVehicles(vehiclesData.vehicles)
        setTotalPages(vehiclesData.total_pages)
        setTotalVehicles(vehiclesData.total)
    }

    useEffect(() => {
        getVehicles()
    }, [page, totalResults, showUnpushed])

    const handleSearchChange = (event: any) => {
        const searchValue = event.target.value.toUpperCase()
        setSearch(searchValue)

        if (event.target.value == '') {
            getVehicles()
        }
    }

    return (
        <>
            <Grid container spacing={2} sx={{ width: '100%', maxWidth: { xs: '100%' }, margin: "10px" }}>
                <Grid item display="flex" alignContent="center" justifyContent="left" width="100%">
                    <FormControl style={{ paddingBottom: 15, width: 200 }}>
                        <InputLabel id="demo-simple-select-label">Results per page</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={totalResults}
                            label="Results per page"
                            onChange={(e)=>{
                                setPage(1)
                                setTotalResults(e.target.value)
                            }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField style={{ paddingBottom: 15, marginLeft: 10, width: 200 }} id="outlined-basic" label="Search" variant="outlined" value={search} onChange={handleSearchChange} />
                    <Button style={{ marginLeft: 10, width: 200, height: 55 }} onClick={() => getVehicles()} variant="contained">Search</Button>
                </Grid>
                <Grid item display="flex" alignContent="center" justifyContent="left" width="100%">
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={showUnpushed} onChange={(e)=>{
                            setPage(1)
                            setShowUnpushed(e.target.checked)}
                        } />} label="Only Show Unpushed" />
                    </FormGroup>
                    <Typography variant="body1" justifyContent="center" alignContent="center">Total Vehicles: {totalVehicles}</Typography>
                </Grid>
                <Grid item width="100%">
                    {totalVehicles && <VehicleGrid vehicles={vehicles} />}
                    <Pagination showFirstButton showLastButton style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }} color="primary" count={totalPages} page={page} onChange={(event, value) => setPage(value)} />
                </Grid>
            </Grid></>
    )
}

