import { VehicleActions } from './action_types'
import { Action } from './actions'

const vehicleReducer = (state: any, action: Action): any => {
  switch (action.type) {
    case VehicleActions.CREATE_VEHICLE:
      return {
        ...action.payload,
      }

    case VehicleActions.UPDATE_VEHICLE:
      return {
        ...action.payload,
      }

    case VehicleActions.LIST_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}

export default vehicleReducer
