import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CameraForm from './CameraForm';
import CameraCard from './CameraCard';
import { list_cameras } from 'state/camera/action_creator';
import { bindActionCreators } from 'redux';
import { State } from 'state/reducers'
import { ReactComponent as AddCameraSVG } from 'assets/images/add-camera.svg';
import { Box, Button } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid';
import { Camera, CAMERA_TYPES } from 'shared/interfaces/camera.interface';
import RadioButtonGroup from './RadioButtonGroup';
import { getCameraPreviewFromApi } from './cameraService';

export default function CameraSettings() {
  const dispatch = useDispatch()
  const getCameras = bindActionCreators(list_cameras, dispatch);

  let cameras = useSelector((state: State) => state.camera.cameras);
  cameras?.sort((a: any, b: any) => (a.camera_position > b.camera_position) ? 1 : -1)

  const [cameraDisplayType, setCameraDisplayType] = useState<string>(CAMERA_TYPES.EXTERIOR)
  const [cameraList, setCameraList] = useState<Camera[]>(cameras)
  const [cameraFormMode, setCameraFormMode] = useState<"Add" | "Edit">("Add")
  const [selectedCamera, setSelectedCamera] = useState<Camera | null>(null)


  const [imagePreview, setImagePreview] = useState<string>('')
  const [previewCamera, setPreviewCamera] = useState<Camera | null>(null)

  useEffect(() => {
    getCameras()
  }, [])

  useEffect(() => {
    const filteredCameras = cameras?.filter((filteredCamera: Camera) => filteredCamera.type == cameraDisplayType).sort((a: Camera, b: Camera) => a.order - b.order)
    setCameraList(filteredCameras)
    setSelectedCamera(null)
    setPreviewCamera(null)
  }, [cameraDisplayType, cameras])

  const addCamera = () => {
    setCameraFormMode('Add')
    setPreviewCamera(null)

    let lastCameraPosition = 0
    
    if (cameraList.length > 0) {
      lastCameraPosition = cameraList.sort((a: Camera, b: Camera) => a.camera_position - b.camera_position)[cameraList.length - 1].camera_position
    }

    setSelectedCamera({
      camera_position: lastCameraPosition + 1,
      type: cameraDisplayType,
      brand: cameraDisplayType === CAMERA_TYPES.EXTERIOR ? 'axis' : 'theta'
    } as Camera)
  }

  const editCamera = (selectedCameraId: number) => {
    const selectedCamera = cameraList.filter((camera: Camera) => camera.id === selectedCameraId)[0]
    setCameraFormMode('Edit')
    setSelectedCamera(selectedCamera)
    setPreviewCamera(null)
  }

  const closeModal = () => {
    setSelectedCamera(null)
  }

  const getCameraPreview = (selectedCameraId: number) => {
    setSelectedCamera(null)
    if (previewCamera?.id !== selectedCameraId) {
      const selectedCamera = cameraList.filter((camera: Camera) => camera.id === selectedCameraId)[0]
      setPreviewCamera(selectedCamera)
      getCameraPreviewFromApi(selectedCamera.id)
        .then((response) => {
          setImagePreview(response.data)
        })
    } else {
      setImagePreview('')
      setPreviewCamera(null)
    }
  }

  return (
      <Grid container spacing={2}>
        <Grid xs={4}>
          <Grid container display={'flex'} justifyContent={'center'} marginBottom={1}>
            <Grid xs={8} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <RadioButtonGroup setter={setCameraDisplayType} getter={cameraDisplayType} />
            </Grid>
            <Grid xs={4} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Button onClick={() => addCamera()} startIcon={<AddCameraSVG />} />
            </Grid>
          </Grid>
          <Box
            sx={{
              height: 520,
              overflowY: 'auto',
              padding: 1.5,
              borderColor: '#ccc',
              borderWidth: 4,
              borderRadius: 1
            }}
          >
            {
              cameraList?.map((camera: Camera) => (
                <CameraCard
                  key={camera.id}
                  refreshCameras={getCameras}
                  camera={camera}
                  editCamera={() => editCamera(camera.id)}
                  cameraPreview={() => getCameraPreview(camera.id)}
                />
              ))
            }
          </Box>
        </Grid>
        <Grid xs={8}>
          {previewCamera && (cameraDisplayType === CAMERA_TYPES.EXTERIOR) &&
            <>
              <h1>Camera {previewCamera.camera_position} Preview</h1>
              <img
                src={`data:image/png;base64,${imagePreview}`}
                alt=""
              />
            </>
          }
          {
            selectedCamera &&
            <CameraForm
              close={() => closeModal()}
              camera={selectedCamera}
              camera_position={selectedCamera?.camera_position ?? cameras?.length + 1}
              mode={cameraFormMode}
            />
          }
        </Grid>
      </Grid>
  )
}