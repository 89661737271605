import { Button, Grid, Paper, styled, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { CAMERA_TYPES, CameraCardProps } from "shared/interfaces/camera.interface";
import { Rooftop } from "shared/interfaces/rooftop.interface";
import { State } from "state/reducers";
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideocamIcon from '@mui/icons-material/Videocam';

const StyledSpan = styled('span')({})

export default function CameraCard(props: CameraCardProps) {
  let rooftops = useSelector((state: State) => state.rooftop.rooftops)
  const { camera, cameraPreview, editCamera } = props

  const theme = useTheme()

  const cameraAssignedToRooftop = () => {
    return rooftops.filter((rt:Rooftop) => rt.cameras.includes(camera.id)).length > 0
  }


  return (
    <Paper sx={{ borderRadius: 1, marginBottom: 1, padding: 0.2, ":hover": { backgroundColor: '#ddd', cursor: 'pointer' } }} onClick={editCamera}>
      <Grid container padding={1} spacing={0.5} justifyContent={'center'}>
        <Grid item xs={3}  display={'flex'} justifyContent={'center'}>
          {cameraAssignedToRooftop() ?
            <StyledSpan sx={{ color: theme.palette.success.light }}>
              <VideocamIcon sx={{fontSize: 55}} />
            </StyledSpan>
            :
            <StyledSpan sx={{ color: theme.palette.error.dark }}>
              <VideocamOffIcon sx={{fontSize: 50}}/>
            </StyledSpan>
          }
        </Grid>
        <Grid item xs={6} alignContent={'center'}>
            <Typography textAlign={'center'}>
              {camera.type === CAMERA_TYPES.EXTERIOR ?
                `Camera: ${camera.camera_position}` :
                camera.username}
            </Typography>
            <Typography textAlign={'center'}>
              {camera.type === CAMERA_TYPES.EXTERIOR &&
                `Tag: ${camera.tag}`} 
            </Typography>
        </Grid>

        <Grid item xs={3} display={'flex'} justifyContent={'center'}>
          {camera.brand === "axis" && (
            <Button
              onClick={(event) => {
                event.stopPropagation(); // Prevents the Paper's onClick event
                cameraPreview();
              }}
              variant="contained"
              size="small"
            >
              Preview
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}
