import { Divider, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Rooftop } from 'shared/interfaces/rooftop.interface';
import BWTextField from 'components/shared/BWTextInput';
import BWNumberField from 'components/shared/BWNumberInput';

interface RooftopSettingsProps {
  rooftopState: Rooftop;
  handleRooftopChange: (key: string, value: any) => void;
}

const RooftopSettings = ({
  rooftopState,
  handleRooftopChange,
}: RooftopSettingsProps): ReactElement => {
  const handleDefaultRooftopChange = (e: any) => {
    if (e.target.checked) {
      handleRooftopChange('is_default', true)
    }
  }

  const handleDisplayTabletRooftopChange = (e: any) => {
    if (e.target.checked) {
      handleRooftopChange('is_display_tablet_rooftop', true)
    }
  }

  return (
    <div>
      <Typography variant="h5" gutterBottom>General Settings</Typography>
      <Divider />
      <br />
      <BWTextField
        label="URL"
        onChange={(value)=> handleRooftopChange('url', value)}
        value={rooftopState.url}
      />
      <BWTextField
        label="Name"
        onChange={(value) => handleRooftopChange('name', value)}
        value={rooftopState.name}
      />
      <BWTextField
        label="Bucket"
        onChange={(value) => handleRooftopChange('s3_bucket', value)}
        value={rooftopState.s3_bucket}
      />
      <BWNumberField
        label='Location Identifier'
        onChange={(value) => handleRooftopChange('location_id', value)}
        value={rooftopState.location_id}
      />
      <BWNumberField
        label='Identifier Length (default 17)'
        onChange={(value) => handleRooftopChange('vin_length', value)}
        value={rooftopState.vin_length}
      />
      <BWTextField
        label='Identifier Terminology'
        onChange={(value) => handleRooftopChange('vin_scan_terminology', value)}
        value={rooftopState.vin_scan_terminology}
      />
      <BWTextField
        label='Vin Confirmation Terminology'
        onChange={(value) => handleRooftopChange('vin_feedback_terminology', value)}
        value={rooftopState.vin_feedback_terminology}
      />
      <div className='mb-2'>
        <FormControlLabel control={<Checkbox checked={rooftopState.is_default} onChange={handleDefaultRooftopChange} />} label="Default" />
      </div>
      <div className='mb-2'>
        <FormControlLabel control={<Checkbox checked={rooftopState.is_display_tablet_rooftop} onChange={handleDisplayTabletRooftopChange} />} label="Tablet Rooftop" />
      </div>
      <div className='mb-2'>
        <FormControlLabel control={<Checkbox checked={rooftopState.use_lighting_controller} onChange={() => handleRooftopChange("use_lighting_controller", !rooftopState.use_lighting_controller)} />} label="3.0 Lighting" />
      </div>
      <div className='mb-2'>
        <FormControlLabel control={<Checkbox checked={rooftopState.skip_interior_simultaneous} onChange={() => handleRooftopChange("skip_interior_simultaneous", !rooftopState.skip_interior_simultaneous)} />} label="Skip Interior Simultaneous" />
      </div>
    </div>
  );
};

export default RooftopSettings;
