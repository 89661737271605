import axios from "axios";
import { FormValidationActions } from "state/FormValidation/action_types";
import { AlertActions } from "../../state/alert/action_types";
import store from "../../state/store";

axios.interceptors.response.use(
  function (response) {
    store.dispatch({
      type: FormValidationActions.RESET,
    })      
    return response;
  },
  function (error) {
    if (error.response.status === 400 || error.response.status === 422) {
      store.dispatch({
        type: FormValidationActions.SET,
        payload: error.response.data,
      })      
    } else {
      store.dispatch({
        type: AlertActions.SHOW,
        message: "Oops! An error occured. Please try again.",
        class: "danger",
        show: true
      })
    }
    return Promise.reject(error);
  }
);