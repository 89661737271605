import { Dispatch } from "redux";
import { Action } from "./actions";
import { AlertActions } from "./action_types";

export const show_alert = (message: string, alert_class: "danger" | "warning" | "info" | "success") => {
  return (dispatch: Dispatch<Action>) => {

    dispatch({
      type: AlertActions.SHOW,
      message: message,
      class: alert_class,
      show: true
    })
  }
}

export const hide_alert = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: AlertActions.HIDE,
    })
  }
}