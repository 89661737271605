import { Divider, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { Rooftop } from 'shared/interfaces/rooftop.interface';

interface PhoneCameraSettingsProps {
  rooftopState: Rooftop;
  handleRooftopChange: (key: string, value: any) => void;
}

const PhoneCameraSettings = ({
  rooftopState,
  handleRooftopChange,
}: PhoneCameraSettingsProps): ReactElement => {
  const cameraResolutionTypes = ['16:9', '4:3'];
  const cameraAngleTypes = ['wide', 'ultra_wide'];

  return (
    <div>
      <Typography variant="h5" gutterBottom>Phone Camera Settings</Typography>
      <Divider />
      <br/>
      <FormControl fullWidth>
        <Typography variant="subtitle1">Manual Camera Aspect Ratio</Typography>
        <Select
          value={rooftopState.phone_camera_resolution}
          onChange={(e) => handleRooftopChange('phone_camera_resolution', e.target.value as string)}
        >
          {cameraResolutionTypes.map((type) => (
            <MenuItem key={type} value={type}>{type}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <br/><br/>
      <FormControl fullWidth>
        <Typography variant="subtitle1">Adhoc Manual Camera Lens</Typography>
        <Select
          value={rooftopState.phone_camera_lens}
          onChange={(e) => handleRooftopChange('phone_camera_lens', e.target.value as string)}
        >
          {cameraAngleTypes.map((type) => (
            <MenuItem key={type} value={type}>{type === "wide" ? "normal" : type}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <br/>
      <br/>
    </div>
  );
};

export default PhoneCameraSettings;
