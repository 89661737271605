import { styled } from "@mui/system";
import { ThemeProvider } from '@mui/material';
import { lightTheme, darkTheme } from 'shared/theme/theme';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'state/reducers';
import { ReactComponent as LogoSvg } from 'assets/images/bw-login-logo.svg';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { login } from "state/auth/action_creator";

const StyledDiv = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.primary.main,
}));

export default function Login() {
  const darkThemeMode = useSelector((state: State) => state.theme.darkTheme);
  const authorized = useSelector((state: State) => state.auth.authorized);
  const failed_auth = useSelector((state: State) => state.auth.failed);
  const navigate = useNavigate()

  useEffect(() => {
    if (authorized) {
      navigate("/admin")
    }
  }, [authorized])

  const [passwordVal, setPassword] = useState('')

  const dispatch = useDispatch();
  const login_action = bindActionCreators(login, dispatch);

  const handleAuth = (e: any) => {
    e.preventDefault()
    login_action(passwordVal);
  }

  const handleChange = (event: any) => {
    setPassword(event.target.value)
  }

  return (
    <ThemeProvider theme={darkThemeMode ? darkTheme : lightTheme}>
      <CssBaseline enableColorScheme />
      <form onSubmit={(e: any) => handleAuth(e)}>
        <div className="flex justify-center">
          <StyledDiv className="shadow w-4/5 md:w-1/3 bg-white p-6 mt-10 rounded-md">
            <div>
              <LogoSvg className="mb-4 justify-center" />
            </div>
            <div className="mb-3">
              <div className="mb-2 text-sm">Password</div>
              <input type="password"
                className='w-full bg-gray-100 rounded p-2 mb-1 shadow-inner focus:outline-none'
                onChange={(e) => handleChange(e)}
                value={passwordVal}
              />
              {
                failed_auth
                  ?
                  <div className='text-xs text-red-700'>
                    <span>Password is incorrect.</span>
                  </div>
                  :
                  null
              }
            </div>

            <button type="submit" className="rounded-pill shadow-sm w-full font-medium uppercase bg-bw-red text-white p-3 hover:brightness-[.90] mb-2">Login</button>
            <button className="rounded-pill shadow-sm w-full font-medium uppercase p-3 hover:bg-gray-100 border-black border-1" onClick={() => navigate("/")}>Back</button>
          </StyledDiv>
        </div>
      </form>
    </ThemeProvider>
  );
}