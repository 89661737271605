import {Camera, CAMERA_TYPES} from "../../shared/interfaces/camera.interface";
import { Dispatch } from "redux";
import { Action } from "./actions";
import { CameraActions } from "./action_types";
import axios from "axios";

export const create_camera = (camera: Camera) => {
  return async (dispatch: Dispatch<Action>) => {
    // we need to set this to an empty array because the backend expects an array of rooftops
    camera.rooftops = []
    const response = await axios.post(`/api/cameras/`, camera)

    dispatch({
      type: CameraActions.CREATE,
      payload: response.data
    })
  }
}

export const update_camera = (camera: Camera) => {
  return async (dispatch: Dispatch<Action>) => {
    const response = await axios.put(`/api/cameras/${camera.id}/`, camera)

    dispatch({
      type: CameraActions.UPDATE,
      payload: response.data
    })
  }
}

export const reorder_cameras = (cameras: Camera[]) => {
  return async (dispatch: Dispatch<Action>) => {
    let updated_cameras = [];

    const exterior_cams = cameras.filter((camera: Camera) => camera.type === CAMERA_TYPES.EXTERIOR);
    const interior_cams = cameras.filter((camera: Camera) => camera.type === CAMERA_TYPES.INTERIOR);

    for (let i = 0; i < exterior_cams.length; i++) {
      let camera = exterior_cams[i];
      camera.camera_position = i+1
      if (camera.id) {
        updated_cameras.push(camera)
        await axios.put(`/api/cameras/${camera.id}/`, camera)
      } else {
        camera.rooftops = []
        const res = await axios.post('/api/cameras/', camera)
        updated_cameras.push(res.data)
      }
    }

    updated_cameras = updated_cameras.concat(interior_cams)

    dispatch({
      type: CameraActions.REORDER,
      payload: updated_cameras
    })
  }
}

export const list_cameras = () => {
  return async (dispatch: Dispatch<Action>) => {
    const response = await axios.get(`/api/cameras/`)

    dispatch({
      type: CameraActions.LIST,
      payload: response.data
    })
  }
}

export const delete_camera = (id: number) => {
  return async (dispatch: Dispatch<Action>) => {
    await axios.delete(`/api/cameras/${id}/`)
    dispatch({
      type: CameraActions.DELETE,
      id: id
    })
  }
}

export const barcode_update = (barcode: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: CameraActions.BARCODE,
      barcode: barcode
    })
  }
}