import { Snackbar } from "@mui/material";
import { useState } from "react";

interface SnackBarProps {
    linker: boolean
}

export function SnackBar(props: SnackBarProps) {
    return (
        <div>
            <Snackbar
                open={props.linker}
                message="Overlay copied to clipboard!"
            ></Snackbar>
        </div>
    )
}