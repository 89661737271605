import { Grid, Button, Divider } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

export default function VehicleSearch() {
  const navigate = useNavigate();

  return (
    <Grid container width='100%'>
      <Grid item xs={12} alignContent="center" marginBottom="20px">
        <Button
          onClick={() => { navigate('/admin/vehicle') }}
          className={`button selected`}
          sx={{ marginLeft: '20px', alignSelf: 'center' }}
        >
          Vehicle Search
        </Button>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Outlet />
      </Grid>
    </Grid>
  )
}