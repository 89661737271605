import { Autocomplete, Box, Button, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DropBox from "./DropBox";
import "./module-form.css"
import { createModule, getModules, updateModule } from "./moduleService";

function ModuleForm() {
    const [moduleName, setModuleName] = useState<string>("");
    const [modules, setModules] = useState<any[]>([]);
    const [importedModuleName, setImportedModuleName] = useState<string>("");

    useEffect(() => {
        getModules().then((response) => {
            setModules(response.data);
        })
    }, [])

    const getOrder = async () => {
        const response = await getModules();

        if (response.data.length == 0) {
            return 1;
        }

        let orderCount = response.data[response.data.length - 1].order;
        return orderCount + 1;
    }

    const addModule = async () => {
        const order = await getOrder();

        await createModule({
            name: moduleName,
            enabled: true,
            order: order
        })

        setModuleName("");
        const result = await getModules();
        setModules(result.data);
    }

    const handleAutoSelect = (event: any) => {
        let moduleName = event.target.textContent;
        let module = modules.filter((module) => module.name == moduleName)[0];

        handleCheck(module);
    }

    const handleChange = (event: any) => {
        setModuleName(event.target.value);
    }

    const enabledModules = modules.filter((module) => module.enabled == true);

    const reorder = async (modules: any) => {
        await axios.put('/api/module/reorder/', {
            modules: modules
        })
        
        const result = await getModules()
        setModules(result.data)
    }

    const handleCheck = async (module: any) => {
        module.enabled = !module.enabled
        await updateModule(module)
        await reorder(modules)
        const result = await getModules()
        setModules(result.data)
    }

    const onDragEnd = async (result: any) => {
        const newModuleOrder = modules.filter((module) => module.enabled == true)
        const [removed] = newModuleOrder.splice(result.source.index, 1)
        newModuleOrder.splice(result.destination.index, 0, removed)

        await reorder(newModuleOrder)
    }

    return (
        <div className="main-module-form-container">
            <div>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={importedModuleName}
                    options={modules.filter((module) => module.enabled == false).map((module) => module.name)}
                    sx={{ width: 300 }}
                    onChange={handleAutoSelect}
                    renderInput={(params) => <TextField {...params} label="Import Disabled Modules" />} />
            </div>
            <hr className="solid-line-divider" />
            <div className="module-form-text">
                <h2>Currently Enabled Modules: </h2>
            </div>
            <div style={{ height: enabledModules.length * 92 }}>
                <DragDropContext onDragEnd={async (result) => await onDragEnd(result)}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div className="module-form-droppable" {...provided.droppableProps} ref={provided.innerRef}>
                                {enabledModules.map((module, index) => (
                                    <Draggable key={module.id} draggableId={module.id.toString()} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <DropBox module={module} updateEnabled={async () => await handleCheck(module)} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div className="module-form-container">
                <div className="module-form-subcontainer">
                    <TextField className="module-form-textfield" id="module-form-text" label="Module Name" value={moduleName} variant="outlined" onChange={handleChange} />
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'start', paddingTop: 1.75 }}>
                    <Button className="module-form-button" variant="contained" onClick={async () => await addModule()}>Add Module</Button>
                </Box>
            </div>
        </div>
    )
}

export default ModuleForm;