import { Checkbox, FormControlLabel, Grid, Paper, Typography } from "@mui/material";
import { OverlayDTO } from "../overlay/canvas/table/OverlayTable";
import { Rooftop } from "shared/interfaces/rooftop.interface";
import { bindActionCreators } from "redux";
import { update_rooftop } from "state/rooftop/action_creator";
import { useDispatch } from "react-redux";

interface OverlayCardProps {
  overlay: OverlayDTO
  index: number
  rooftop: Rooftop
}

enum Field {
  MANDATORY = "mandatory",
  ULTRA_WIDE = "ultra_wide"
}

export function OverlayCard(props: OverlayCardProps) {
  const dispatch = useDispatch()
  const update = bindActionCreators(update_rooftop, dispatch)

  const updateField = (field: Field) => {
    const updatedOverlay = (field === "mandatory") ? {
      id: props.overlay.id,
      order: props.overlay.order,
      phone_camera_lens: props.overlay.phone_camera_lens,
      manditory: !props.overlay.manditory
    } : {
      id: props.overlay.id,
      order: props.overlay.order,
      manditory: props.overlay.manditory,
      phone_camera_lens: props.overlay.phone_camera_lens === "ultra_wide" ? "wide" : "ultra_wide"
    }

    const updatedOverlays = props.rooftop.overlays.map(rooftopOverlay => {
      if (rooftopOverlay.id === props.overlay.id) {
        return updatedOverlay
      } else {
        return {
          id: rooftopOverlay.id,
          order: rooftopOverlay.order,
          manditory: rooftopOverlay.manditory,
          phone_camera_lens: rooftopOverlay.phone_camera_lens
        }
      }
    })

    const updatedRooftop = {
      ...props.rooftop,
      overlays: updatedOverlays
    }

    update(updatedRooftop)
  }

  return (
    <div>
      <Paper className="draggable-container" elevation={3}>
        <Grid container display='flex' justifyContent='center' alignItems='center'>
          <Grid item xs={2} display='flex' justifyContent='center' alignItems='center'>
            <Typography>{`${props.index + 1}.`}</Typography>
          </Grid>
          <Grid item xs={3} display='flex' flexDirection='column' alignItems='center'>
            <Typography>{props.overlay.name}</Typography>
            <Typography>{props.overlay.sub_type !== null ? `sub-type: ${props.overlay.sub_type}` : null}</Typography>
          </Grid>
          <Grid item xs={4} display='flex' flexDirection='column' alignItems='center'>
            <img className="draggable-image" src={props.overlay.img}></img>
          </Grid>
          <Grid item xs={3} display='flex' flexDirection='column' alignItems='center'>
            <FormControlLabel control={<Checkbox checked={props.overlay.manditory} onChange={async () => await updateField(Field.MANDATORY)} />} label="Mandatory" />
            <FormControlLabel control={<Checkbox checked={props.overlay.phone_camera_lens === "ultra_wide"} onChange={async () => await updateField(Field.ULTRA_WIDE)} />} label="Ultra Wide" />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}