import { Rooftop } from "./rooftop.interface"

export type CameraCardProps = {
    camera: Camera
    editCamera: () => void
    cameraPreview: () => void
    refreshCameras: () => void
}

export enum CAMERA_TYPES {
    EXTERIOR = "exterior",
    INTERIOR = "interior",
}
export interface Camera {
    id: number,
    ip_address: string,
    type: CAMERA_TYPES.EXTERIOR | CAMERA_TYPES.INTERIOR,
    camera_position: number,
    username: string,
    password: string,
    brand: string,
    name?: string,
    query_params?: string | null,
    order: number,
    rooftops?: Rooftop[],
    tag: string,
    skip_ricoh_while_charging?: boolean,
}
