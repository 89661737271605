import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { update_rooftop } from 'state/rooftop/action_creator';
import { Module } from 'shared/interfaces/rooftop.interface';
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Divider, Grid, Paper, Typography } from '@mui/material'
import './styles.css';

interface WizardSettingsProps {
    rooftop: any
}

const WizardSettings = ({ rooftop }: WizardSettingsProps) => {
    const dispatch = useDispatch()

    const [moduleList, setModuleList] = useState<{ [key: string]: any }>({
        enabled: Object.values(rooftop?.enabled || {}),
        disabled: Object.values(rooftop?.disabled || {})
    });

    useEffect(() => {
        setModuleList({
            enabled: Object.values(rooftop?.enabled || {}),
            disabled: Object.values(rooftop?.disabled || {})
        });
    }, [rooftop]);

    const handleDrop = (result: DropResult): void => {
        if (!result.destination) return;

        const { source, destination } = result;
        const sourceList = source.droppableId;
        const destinationList = destination.droppableId;

        if (sourceList === destinationList) {
            const newList = [...moduleList[sourceList]];
            const [reorderedItem] = newList.splice(source.index, 1);
            newList.splice(destination.index, 0, reorderedItem);

            const newModuleList = { ...moduleList, [sourceList]: newList };
            setModuleList(newModuleList);

            const rooftopCopy = { ...rooftop };
            rooftopCopy[sourceList] = arrayToObject(newList);
            dispatch(update_rooftop(rooftopCopy));
        } else {
            const currentSourceList = [...moduleList[sourceList]];
            const currentDestinationList = [...moduleList[destinationList]];
            const [reorderedItem] = currentSourceList.splice(source.index, 1);
            currentDestinationList.splice(destination.index, 0, reorderedItem);

            const newModuleList = {
                ...moduleList,
                [sourceList]: currentSourceList,
                [destinationList]: currentDestinationList
            };
            setModuleList(newModuleList);

            const rooftopCopy = { ...rooftop };
            rooftopCopy.enabled = arrayToObject(newModuleList.enabled);
            rooftopCopy.disabled = arrayToObject(newModuleList.disabled);
            dispatch(update_rooftop(rooftopCopy));
        }

    };

    const arrayToObject = (arr: Module[]) => {
        let res: { [index: number]: Module } = {};
        arr.map((item, index) => {
            res[index] = item;
        });
        return res;
    };

    const renderDraggableItems = (items: any[], droppableId: string) => {
        return (
            <Paper elevation={4}>
                <Typography fontSize={'18px'}>
                    {droppableId === "enabled" ? "Enabled Modules" : "Disabled Modules"}
                </Typography>
                <Divider />
                <Droppable droppableId={droppableId}>
                    {(provided, snapshot) => (
                        <Grid
                            className="list-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                                background: snapshot.isDraggingOver ? '#ddd' : '#eee'
                            }}
                        >
                            {items.map((item, index) => (
                                <Draggable key={item.name} draggableId={item.name} index={index}>
                                    {(provided) => (
                                        <div
                                            className="item-container"
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                        >
                                            {item.name}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </Paper>
        )
    };


    return (
        <>
            <Typography variant="h5" gutterBottom>Wizard Module Settings</Typography>
            <Divider />
            <br />
            <DragDropContext onDragEnd={handleDrop}>
                {renderDraggableItems(moduleList.enabled, "enabled")}
                <Divider />
                {renderDraggableItems(moduleList.disabled, "disabled")}
            </DragDropContext>
        </>
    )
}

export default WizardSettings;