import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#B11D29',
    },
    secondary: {
      main: '#333333'
    },
    background: {
      default: '#F5F5F5',
      paper: '#fff'
    },
    success: {
      main: '#66bb6a'
    }
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#B11D29',
    },
    secondary: {
      main: '#333333'
    },
    background: {
      default: '#303030',
      paper: '#424242'
    },
    success: {
      main: '#66bb6a'
    }    
  },
});

