import { Button, Divider, Grid } from "@mui/material"
import { ReactElement, useState } from "react"

type tab = { label: string, component: ReactElement }

export default function SubTabs({ tabs }: { tabs: tab[] }) {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)

    return (
        <div style={{ marginTop: '10px' }}>
            <Grid container direction="row">
                {
                    tabs.map((tab, index) => (
                        <Grid item key={tab.label} xs={6} sm={4} md={3} textAlign="center" alignContent="center" justifyContent='center'>
                            <Button
                                key={index}
                                onClick={() => setSelectedTabIndex(index)}
                                style={selectedTabIndex === index ? {
                                    color: '#B11D29',
                                    width: '100%',
                                    borderBottom: 'solid 2px #B11D29'
                                } : { color: 'grey' }}
                            >
                                {tab.label}
                            </Button>
                        </Grid>
                    ))
                }
            </Grid>
            <Divider />
            <div style={{ marginTop: '10px' }}>
                {tabs.map((tab, index) =>
                    selectedTabIndex === index && <div key={index}>{tab.component}</div>
                )}
            </div>
        </div>
    )
}