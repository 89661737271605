import { Rooftop } from "shared/interfaces/rooftop.interface";
import { RooftopActions } from "./action_types"
import { Action } from "./actions"

const rooftopReducer = (state: any, action: Action): any => {
  switch (action.type) {
    case RooftopActions.CREATE:
      return {
        ...state,
        rooftops: [...state.rooftops, action.payload]
      }

    case RooftopActions.UPDATE:
      const updatedRooftops = state.rooftops.map((rooftop: Rooftop) => {
        if (rooftop.id === action.payload.id) { 
          return action.payload 
        } else { 
          return {
            ...rooftop,
            is_default: action.payload.is_default ? false : rooftop.is_default,
            is_display_tablet_rooftop: action.payload.is_display_tablet_rooftop ? false : rooftop.is_display_tablet_rooftop
          }
        }
      });
      return {
        ...state,
        rooftop: action.payload,
        rooftops: updatedRooftops
      };

    case RooftopActions.GET:
      return {
        ...state,
        rooftop: action.payload
      }

    case RooftopActions.GET_ALL:
      return {
        ...state,
        rooftops: action.payload
      }

    case RooftopActions.DELETE:
      return {
        ...state,
        rooftops: [...state.rooftops.filter((rooftop: any) => rooftop.id !== action.payload.id)]
      }
      
    default:
      return {
        ...state,
      }
  }
}

export default rooftopReducer
