import VehicleDisplay from "./VehicleDisplay";
import { useParams } from "react-router-dom";

export default function VehicleInfo() {
    let {uuid} = useParams();

    return (
        <div style={{height: "auto"}}>
            <VehicleDisplay uuid={uuid!} />
        </div>
    )
}
