import { Link } from 'react-router-dom';
import { IconButton } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/system';
import OnlineInidcator from "./OnlineIndicator";
import { ReactComponent as SpiderSvg } from 'assets/images/bw-spider-white.svg';
import { ReactComponent as WidowTitle } from 'assets/images/bw-logo-no-spider-white.svg';
import './index.scss';

const StyledHeader = styled('header')(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
}));

export default function Header() {

  return (
    <StyledHeader id="header" className="flex justify-content-between px-2 fixed z-10 top-0 w-full">
      <Link to='/admin' className="spider-svg py-2">
        <SpiderSvg className="text-white" />
      </Link>
      <Link to='/admin' className="title-svg py-2 justify-self-center">
        <WidowTitle/>
      </Link>
      <div className="align-self-center">
        <Link to="/admin/settings">
          <IconButton className="settings-icon hover:animate-spin">
            <SettingsIcon fontSize="large" className="text-white" />
          </IconButton>
        </Link>
        <OnlineInidcator />
      </div>
    </StyledHeader>
  );
}
