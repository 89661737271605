import { Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  onChange: (e: number | null) => void;
  label: string;
  value: number | null;
}

export default function CamFormNumInput(props: Props) {
  const [value, setValue] = useState<number | null>(props.value);

  useEffect(() => {
    props.onChange(value);
  }, [value]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (/^\d*\.?\d*$/.test(newValue)) {
      const numValue = e.target.value === '' ? 0 : parseInt(e.target.value)
      setValue(numValue);
    }
  };

  return (
    <>
      <Grid item xs={4}>
        <Typography variant="subtitle1" fontWeight="bold">
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <TextField
          fullWidth
          inputMode="numeric"
          InputLabelProps={{
            style: {
              fontSize: '1.4rem',
              color: 'black',
            },
          }}
          style={{
            backgroundColor: "#e9e9e9",
          }}
          onChange={handleChange}
          value={value ?? ''}
        />
      </Grid>
    </>
  );
}
