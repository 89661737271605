import { Rooftop } from "../../shared/interfaces/rooftop.interface";
import { RooftopActions } from "./action_types";
import { Dispatch } from "redux";
import { Action as RooftopAction } from "./actions";
import axios from "axios";
import { RooftopOverlay } from "components/admin/settings/overlay/canvas/table/OverlayTable";

export const update_rooftop = (rooftop: Rooftop) => {
  return async (dispatch: Dispatch<RooftopAction>) => {
    return axios.put(`/api/rooftop/${rooftop.id}/`, rooftop)
      .then(response => {
        dispatch({
          type: RooftopActions.UPDATE,
          payload: response.data
        })
        return response
      })
  }
}

export const create_rooftop = (rooftop: Rooftop) => {
  return async (dispatch: Dispatch<RooftopAction>) => {
    return axios.post('/api/rooftop/', rooftop)
      .then(response => {
        dispatch({
          type: RooftopActions.CREATE,
          payload: response.data
        })
      })
  }
}

export const delete_rooftop = (rooftop: Rooftop) => {
  return async (dispatch: Dispatch<RooftopAction>) => {
    return axios.delete(`/api/rooftop/${rooftop.id}/`)
      .then(response => {
        dispatch({
          type: RooftopActions.DELETE,
          payload: rooftop
        })
      })
  }
}

export const get_rooftops = () => {
  return async (dispatch: Dispatch<RooftopAction>) => {
    return axios.get('/api/rooftop/')
      .then(response => {
        dispatch({
          type: RooftopActions.GET_ALL,
          payload: response.data
        })
      })
  }
}

export const get_rooftop = (rooftop: Rooftop) => {
  return async (dispatch: Dispatch<RooftopAction>) => {
    dispatch({
      type: RooftopActions.GET,
      payload: rooftop
    })
  }
}

export const manipulate_rooftops = (rooftops: Rooftop[]) => {
  return async (dispatch: Dispatch<RooftopAction>) => {
        const updatedRooftops = rooftops.map((rooftop: Rooftop) => {
          const updatedOverlays = rooftop.overlays.map((overlay: RooftopOverlay) => {
            return {
              id: overlay.id,
              manditory: overlay.manditory,
              order: overlay.order,
              phone_camera_lens: overlay.phone_camera_lens
            }
          })

          return {
            ...rooftop,
            overlays: updatedOverlays
          }
        })

        updatedRooftops.forEach((rooftop: Rooftop) => {
          console.log("updating ", rooftop.name)
          update_rooftop(rooftop)(dispatch)
        });

        dispatch({
          type: RooftopActions.GET_ALL,
          payload: updatedRooftops
        });
  }
}

export const delete_overlay_from_rooftops = (rooftops: Rooftop[], overlayId: number) => {
  return async (dispatch: Dispatch<RooftopAction>) => {
        const updatedRooftops = rooftops.map((rooftop: Rooftop) => {
          const updatedOverlays = rooftop.overlays.filter((overlay: RooftopOverlay) => overlay.id !== overlayId)

          return {
            ...rooftop,
            overlays: updatedOverlays
          }
        })

        updatedRooftops.forEach((rooftop: Rooftop) => {
          console.log("deleting overlay from rooftops", rooftop.name)
          update_rooftop(rooftop)(dispatch)
        });

        dispatch({
          type: RooftopActions.GET_ALL,
          payload: updatedRooftops
        });
  }
}

export const delete_all_overlays_from_rooftops = (rooftops: Rooftop[], overlayId: number) => {
  return async (dispatch: Dispatch<RooftopAction>) => {
        const updatedRooftops = rooftops.map((rooftop: Rooftop) => {
          const updatedOverlays = [] as RooftopOverlay[]

          return {
            ...rooftop,
            overlays: updatedOverlays
          }
        })

        updatedRooftops.forEach((rooftop: Rooftop) => {
          console.log("deleting overlay from rooftops", rooftop.name)
          update_rooftop(rooftop)(dispatch)
        });

        dispatch({
          type: RooftopActions.GET_ALL,
          payload: updatedRooftops
        });
  }
}