import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Vehicle } from "shared/interfaces/vehicle.interface";
import { useNavigate } from "react-router-dom";

interface VehicleGridProps {
    vehicles: any[]
}

// Display a grid of VehicleDisplay components
export default function VehicleGrid(props: VehicleGridProps) {
    const navigate = useNavigate();

    const handleClick = (id: string) => {
        navigate("/admin/vehicle/" + id)
    }

    const formatDate = (timestamp: string) => {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString('en-US'); // mm/dd/yyyy
        const formattedTime = date.toLocaleTimeString('en-US'); // hh:mm:ss AM/PM

        return (
            <>
                <div>{formattedDate}</div>
                <div>{formattedTime}</div>
            </>
        );
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>VIN</TableCell>
                        <TableCell align="right">UUID</TableCell>
                        <TableCell align="right">Ready</TableCell>
                        <TableCell align="right">Pushed</TableCell>
                        <TableCell align="right">Exterior</TableCell>
                        <TableCell align="right">Interior</TableCell>
                        <TableCell align="right">Manual</TableCell>
                        <TableCell align="right">Img Directory</TableCell>
                        <TableCell align="right">Created</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.vehicles.map((vehicle: Vehicle) => (
                        <TableRow
                            key={vehicle.id}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                cursor: 'pointer',
                                '&:hover': { backgroundColor: '#f5f5f5' }
                            }}
                            onClick={() => handleClick(vehicle.id)}
                        >
                            <TableCell component="th" scope="row">
                                {vehicle.vin}
                            </TableCell>
                            <TableCell align="right">{vehicle.id}</TableCell>
                            <TableCell align="right">{vehicle.ready ? "Ready" : "Not Ready"}</TableCell>
                            <TableCell align="right">{vehicle.pushed ? "Pushed" : "Not Pushed"}</TableCell>
                            <TableCell align="right">{vehicle.exterior}</TableCell>
                            <TableCell align="right">{vehicle.interior}</TableCell>
                            <TableCell align="right">{vehicle.manual}</TableCell>
                            <TableCell align="right">{vehicle.image_dir}</TableCell>
                            <TableCell align="right">{formatDate(vehicle.created_at)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}