import { ReactElement } from 'react';
import { Typography, Divider } from '@mui/material';
import { Rooftop } from 'shared/interfaces/rooftop.interface';
import BWNumberField from 'components/shared/BWNumberInput';

interface LegFoldSettingsProps {
  rooftopState: Rooftop;
  handleRooftopChange: (key: string, value: any) => void;
}

const LegFoldSettings = ({
  rooftopState,
  handleRooftopChange,
}: LegFoldSettingsProps): ReactElement => {
  return (
    <>
      <Typography variant="h5" gutterBottom>Leg Fold Settings</Typography>
      <Divider />
      <br />
      <BWNumberField
        onChange={(value) => handleRooftopChange('actuator_fold_trigger_distance', Number(value))}
        value={rooftopState.actuator_fold_trigger_distance}
        label='Fold Trigger Distance (in millimeters)'
      />
      <BWNumberField
        onChange={(value) => handleRooftopChange('actuator_leg_stagger_time', Number(value))}
        value={rooftopState.actuator_leg_stagger_time}
        label='Leg Stagger Time (in seconds)'
      />
      <BWNumberField
        onChange={(value) => handleRooftopChange('actuator_trigger_count_threshold', Number(value))}
        value={rooftopState.actuator_trigger_count_threshold}
        label='Trigger Count Threshold (detected cycles)'
      />
      <BWNumberField
        onChange={(value) => handleRooftopChange('actuator_leg_cycle_time', Number(value))}
        value={rooftopState.actuator_leg_cycle_time}
        label='Leg Cycle Time (in seconds)'
      />
    </>
  );
}

export default LegFoldSettings;
