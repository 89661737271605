import './alert.scss';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hide_alert } from '../../../state/alert/action_creator';
import store from '../../../state/store';
import { useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';

interface Props {
  show?: boolean,
  message?: string
  class?: string
}
export function Alert(props: Props) {
  const [show, setShow] = useState(props.show ?? false)

  store.subscribe(() => {
    const show = store.getState().alert.show;
    if (show !== undefined) {
      setShow(show);
    }
  })

  const alertState = store.getState().alert;

  const message = props.message ? props.message : alertState.message
  const alertClass = props.class ? props.class : alertState.class

  const dispatch = useDispatch()
  const hideAlert = bindActionCreators(hide_alert, dispatch);

  return (
    <div className='flex justify-center'>
      {
        show
          ?
          <div className="w-100 mt-3" >
            <div className={`alert-body flex justify-between ${alertClass}`}>
              <span className='align-self-center'>{message}</span>
              <IconButton onClick={() => hideAlert()}>
                <CloseOutlinedIcon />
              </IconButton>
            </div>
          </div>
          :
          null
      }
    </div>
  )
}