import { useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { Container } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { State } from 'state/reducers';
import { Outlet, useNavigate } from 'react-router-dom';
import { lightTheme, darkTheme } from 'shared/theme/theme';
import './App.scss';
import { Alert } from './components/shared/alert/Alert';
import Header from './components/header/Header';
import { get_rooftops } from 'state/rooftop/action_creator';
import { get_overlays } from 'state/overlays/action_creator';

function App() {
  const darkThemeMode = useSelector((state: State) => state.theme.darkTheme);
  const loginState = useSelector((state: State) => state.auth)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const getRooftopsAction = bindActionCreators(get_rooftops, dispatch);
  const getOverlaysAction = bindActionCreators(get_overlays, dispatch);

  useEffect(() => {
    getRooftopsAction();
    getOverlaysAction();
  }, []);

  useEffect(() => {
    if (!loginState.authorized) {
      navigate('/login')
    }
  }, []);

  return (
    <ThemeProvider theme={darkThemeMode ? darkTheme : lightTheme}>
      <CssBaseline enableColorScheme />
      <Header />
      <Container id="app">
        <Alert />
        <Outlet />
      </Container>
    </ThemeProvider>
  );
}

export default App;
