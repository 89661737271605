import { Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rooftop } from "shared/interfaces/rooftop.interface";
import { State } from "state/reducers";
import RooftopDetails from "./RooftopDetails";
import { bindActionCreators } from "redux";
import { create_rooftop, delete_rooftop, manipulate_rooftops } from "state/rooftop/action_creator";

function RooftopSelector() {
    const rooftops: Rooftop[] = useSelector((state: State) => state.rooftop.rooftops)
    const [rooftopSelection, setRooftopSelection] = useState<number>(rooftops[0].id)
    const dispatch = useDispatch()
    const createAction = bindActionCreators(create_rooftop, dispatch)
    const deleteAction = bindActionCreators(delete_rooftop, dispatch)
    const manipulateRooftopsAction = bindActionCreators(manipulate_rooftops, dispatch)
    const handleChange = (event: SelectChangeEvent) => {
        setRooftopSelection(+event.target.value as number)
    }

    useEffect(() => {
        manipulateRooftopsAction(rooftops)
    }, [])

    const handleDeleteRooftop = () => {
        if (rooftops.length === 1) {
            return
        }

        const rooftopToDelete = rooftops.find((rooftop: Rooftop) => rooftop.id === rooftopSelection)
        if (rooftopToDelete) {
            deleteAction(rooftopToDelete)
            const newSelection = rooftops.find((rooftop: Rooftop) => rooftop.name !== rooftopToDelete.name)?.id || rooftops[0].id
            setRooftopSelection(newSelection)
        }
    }

    const handleCreateNewRooftop = () => {
        const rooftopToCopy = rooftops.find((rooftop: Rooftop) => rooftop.id === rooftopSelection)
        if (rooftopToCopy) {
            const clone = { ...rooftopToCopy }
            clone.name = `${rooftopToCopy.name} ${rooftops.length}`
            clone.is_default = false
            clone.is_display_tablet_rooftop = false
            createAction(clone)
        }
    }

    return (
        <Grid container spacing={2} sx={{ width: '100%', maxWidth: { xs: '100%', md: '66.67%' } }}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="rooftop-select-label">Rooftop</InputLabel>
                    <Select
                        labelId="rooftop-select-label"
                        value={rooftopSelection.toString()}
                        label="Rooftop"
                        onChange={handleChange}
                    >
                        {rooftops.map((rooftop: Rooftop) =>
                            <MenuItem key={rooftop.id} value={rooftop.id}>{rooftop.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
                <Button onClick={handleCreateNewRooftop} variant="contained" fullWidth>Duplicate Rooftop</Button>
            </Grid>
            <Grid item xs={12} md={6}>
                <Button onClick={handleDeleteRooftop} variant="contained" fullWidth>Delete Rooftop</Button>
            </Grid>
            <Grid item xs={12}>
                <RooftopDetails rooftopId={rooftopSelection} />
            </Grid>
        </Grid>
    )
}

export default RooftopSelector;
