import CloseIcon from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import { Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Paper, Select, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import CamFormNumInput from './CamFormNumInput';
import CamFormRooftops from './CamFormRooftops';
import CamFormTextInput from './CamFormTextInput';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CAMERA_TYPES, Camera } from 'shared/interfaces/camera.interface';
import { create_camera, delete_camera, reorder_cameras, update_camera } from 'state/camera/action_creator';
import CameraTags from 'shared/enums/camera_tags';
import { State } from 'state/reducers'

interface Props {
  close: () => void;
  camera: Camera;
  mode: 'Add' | 'Edit';
  camera_position: number;
}

export default function CameraForm(props: Props) {
  const StyledButton = styled(Button)(() => ({
    borderColor: 'black',
    color: 'black',
    borderRadius: 8,
    textTransform: 'capitalize',
  }));

  const [cameraState, setCameraState] = useState<Camera>(props.camera);

  const dispatch = useDispatch();
  const create = bindActionCreators(create_camera, dispatch);
  const update = bindActionCreators(update_camera, dispatch);
  const reorder = bindActionCreators(reorder_cameras, dispatch);
  const delete_cam = bindActionCreators(delete_camera, dispatch);
  const cameraList = useSelector((state: State) => state.camera.cameras);

  useEffect(() => {
    setCameraState(props.camera)
  }, [props])

  const handleCameraChange = (key: string, value: any) => {
    const newCameraState = {
      ...cameraState,
      [key]: value
    }
    setCameraState(newCameraState)
  };

  const saveCameraDetails = () => {
    if (cameraState.type === CAMERA_TYPES.EXTERIOR) {
      const originalCameraPosition = cameraList.filter((cam: Camera) => cam.id === cameraState.id)[0].camera_position
      if (originalCameraPosition !== cameraState.camera_position) {
        const otherCameras = cameraList.filter((cam: Camera) => cam.id !== cameraState.id)
        const newCameraPosition = cameraState.camera_position > originalCameraPosition ? cameraState.camera_position + .5 : cameraState.camera_position - .5
        const updatedCameraState = {...cameraState, camera_position: newCameraPosition}
        const updatedCameraList = [...otherCameras, updatedCameraState].sort((a: any, b: any) => (a.camera_position > b.camera_position) ? 1 : -1)
        reorder(updatedCameraList)
      } else {
        update(cameraState)
      }
    }

    if (cameraState.type === CAMERA_TYPES.INTERIOR) {
      update(cameraState)
    }
    closeModal();
  };

  const add2CameraDetails = () => {
    if (cameraState.type === CAMERA_TYPES.EXTERIOR) {
      const matchingCameraPosition = cameraList.filter((cam: Camera) => cam.camera_position === cameraState.camera_position).length > 0
      if (matchingCameraPosition) {
        const updatedCameraState = {
          ...cameraState, 
          camera_position: cameraState.camera_position - .5
        }
        const updatedCameraList = [...cameraList, updatedCameraState].sort((a: any, b: any) => (a.camera_position > b.camera_position) ? 1 : -1)
        reorder(updatedCameraList)
      } else {
        create(cameraState)
      }
    }

    if (cameraState.type === CAMERA_TYPES.INTERIOR) {
      create(cameraState)
    }

    closeModal();
  };

  const deleteCamera = () => {
    delete_cam(props.camera.id);
    const otherCameras = cameraList.filter((cam: Camera) => cam.id !== cameraState.id)
    reorder(otherCameras)
    closeModal();
  };

  const closeModal = () => {
    props.close();
  };

  return (
    <Paper sx={{ borderRadius: 5, padding: 2 }}>
      <Grid container justifyContent="space-between" mb={2}>
        <Typography variant="h6" color="textSecondary">
          {props.mode} Camera
        </Typography>
        <IconButton onClick={closeModal}>
          <CloseIcon sx={{ color: grey[900] }} />
        </IconButton>
      </Grid>

      <Grid container spacing={2}>
        {(cameraState.type === CAMERA_TYPES.EXTERIOR) && (

          <CamFormNumInput
            label='Camera Position'
            value={cameraState.camera_position}
            onChange={(value) => handleCameraChange("camera_position", value)}
          />
        )}
        <CamFormTextInput
          label='IP Address'
          value={cameraState.ip_address}
          onChange={(value) => handleCameraChange("ip_address", value)}
        />

        {cameraState.type === CAMERA_TYPES.EXTERIOR &&
          <>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                Camera Tag:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <Select
                  labelId="camera-tag-label"
                  value={cameraState.tag || ''}
                  onChange={(e) => handleCameraChange("tag", e.target.value)}
                  style={{
                    backgroundColor: "#e9e9e9",
                  }}
                >
                  {Object.values(CameraTags).map((tag, index) => (
                    <MenuItem key={index} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        }

        <CamFormTextInput
          label='Username'
          value={cameraState.username}
          onChange={(value) => handleCameraChange("username", value)}
        />

        <CamFormTextInput
          label='Password'
          value={cameraState.password}
          onChange={(value) => handleCameraChange("password", value)}
        />

        <CamFormTextInput
          label='Name'
          value={cameraState.name || ''}
          onChange={(value) => handleCameraChange("name", value)}
        />

        {cameraState.type === CAMERA_TYPES.EXTERIOR &&
          <CamFormTextInput
            label='Query Params'
            secondaryLabel={`http://${cameraState.ip_address}/axis-cgi/jpg/image.cgi`}
            value={cameraState.query_params || ''}
            onChange={(value) => handleCameraChange("query_params", value)}
          />
        }

        {props.mode === 'Edit' &&
          <CamFormRooftops
            camera={cameraState}
            onChange={(value) => handleCameraChange("rooftops", value)}
          />
        }

        {cameraState.type === CAMERA_TYPES.INTERIOR &&
          <>
            <Grid item xs={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                Skip Ricoh While Charging:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => handleCameraChange("skip_ricoh_while_charging", !cameraState.skip_ricoh_while_charging)}
                    checked={cameraState.skip_ricoh_while_charging} />
                }
                label="Skip Ricoh While Charging" />
            </Grid>
          </>
        }
      </Grid>

      <Grid container justifyContent={props.mode === 'Edit' ? 'space-between' : 'flex-end'} mt={2}>
        {props.mode === 'Edit' && (
          <Button onClick={deleteCamera} sx={{ textTransform: 'capitalize', paddingLeft: 0 }} startIcon={<Delete />}>
            Delete Camera
          </Button>
        )}
        <Grid item>
          <StyledButton onClick={closeModal} sx={{ ':hover': { backgroundColor: grey[300] } }}>
            Cancel
          </StyledButton>
          <StyledButton
            onClick={props.mode === 'Add' ? add2CameraDetails : saveCameraDetails}
            variant="outlined"
          >
            Save
          </StyledButton>
        </Grid>
      </Grid>
    </Paper>
  );
}
